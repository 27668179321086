import React, { FC, useContext } from "react";
import cn from "classnames";

import { AppContext } from "../../App";

interface Props {
    onNextStepClick?: () => void;
    onPrevStepClick?: () => void;
}

const StepProgress: FC<Props> = ({
    onNextStepClick,
    onPrevStepClick,
    ...props
}) => {
    const { step } = useContext(AppContext);

    const step1ClickHandler = () => {
        if (step === 2) {
            onPrevStepClick && onPrevStepClick();
        }
    };

    const step2ClickHandler = () => {
        if (step === 1) {
            onNextStepClick && onNextStepClick();
        } else if (step === 3) {
            onPrevStepClick && onPrevStepClick();
        }
    };

    const step3ClickHandler = () => {
        if (step === 2) {
            onNextStepClick && onNextStepClick();
        }
    };

    return (
        <div className="tab-area text-center">
            <ul className="nav nav-tabs orderSteps">
                <li
                    className="nav-item"
                    onClick={step1ClickHandler}
                    style={{
                        cursor: step === 2 ? "pointer" : "default",
                    }}
                >
                    <span
                        className={cn([
                            step > 1 ? "visited" : "",
                            step === 1 ? "current" : "",
                        ])}
                        data-toggle="tab"
                    >
                        <b>Krok 1</b>
                        <div className="stepDescription">Kontaktné údaje</div>
                    </span>
                </li>
                <li
                    className="nav-item"
                    onClick={step2ClickHandler}
                    style={{
                        cursor:
                            step === 1 || step === 3 ? "pointer" : "default",
                    }}
                >
                    <span
                        className={cn([
                            step > 2 ? "visited" : "",
                            step === 2 ? "current" : "",
                        ])}
                        data-toggle="tab"
                    >
                        <b>Krok 2</b>
                        <div className="stepDescription">
                            Detaily objednávky
                        </div>
                    </span>
                </li>
                <li
                    className="nav-item"
                    onClick={step3ClickHandler}
                    style={{
                        cursor: step === 2 ? "pointer" : "default",
                    }}
                >
                    <span
                        className={cn([
                            step > 3 ? "visited" : "",
                            step === 3 ? "current" : "",
                        ])}
                        data-toggle="tab"
                    >
                        <b>Krok 3</b>
                        <div className="stepDescription">
                            Sumarizácia objednávky
                        </div>
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default StepProgress;
