import React from "react";

import Col from "../../../Col";
import Row from "../../../Row";

const HowItWorks = () => {
    let publicUrl = process.env.PUBLIC_URL;

    return (
        <div id="howItWorks" className="howItWorksSection">
            <div className="sbst-offer-area pd-top-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10">
                            <div className="section-title text-center">
                                <h2
                                    className="title wow animated fadeInUp"
                                    data-wow-duration="0.6s"
                                    data-wow-delay="0.1s"
                                >
                                    Ako to prebieha?
                                </h2>
                                <p
                                    className="wow animated fadeInUp"
                                    data-wow-duration="0.6s"
                                    data-wow-delay="0.2s"
                                >
                                    Analýza faktúr za elektrickú energiu nebola
                                    nikdy jednoduchšia.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Row justify="space-around" wrap="wrap">
                        <Col className="hwBox" width={1} align="center">
                            <div
                                className="single-offer wow animated fadeInUp"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                            >
                                <div className="media">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/upload.png`}
                                        alt="stepUpload"
                                    />
                                    <div className="media-body align-self-center">
                                        <div className="smallText">Krok 1</div>
                                        <h6>
                                            Nahráte Vaše faktúry za elektrickú
                                            energiu
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="hwBox" width={1} align="center">
                            <div
                                className="single-offer wow animated fadeInUp"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                            >
                                <div className="media">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/diagnostic.png`}
                                        alt="stepDiagnostic"
                                    />
                                    <div className="media-body align-self-center">
                                        <div className="smallText">Krok 2</div>
                                        <h6>
                                            Energetik Vaše
                                            <br />
                                            faktúry vyhodnotí
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="hwBox" width={1} align="center">
                            <div
                                className="single-offer wow animated fadeInUp"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                            >
                                <div className="media">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/result.png`}
                                        alt="stepResult"
                                    />
                                    <div className="media-body align-self-center">
                                        <div className="smallText">Krok 3</div>
                                        <h6>
                                            Vypracovaný protokol Vám doručíme na
                                            email
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
