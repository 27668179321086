import React, { useState } from "react";

import Modal from "../../../Modal";
import diagnosticsData from "../../../data/diagnostics.json";
import Col from "../../../Col";
import Row from "../../../Row";

const Diagnostics = () => {
    const [detailModal, setDetailModal] = useState(false);
    const [detailPath, setDetailPath] = useState<string | null>(null);
    const [diagIdx, setDiagIdx] = useState(0);
    const [detailIdx, setDetailIdx] = useState(0);

    function nextDetail() {
        if (detailIdx + 1 <= diagnosticsData[diagIdx].detail.length - 1) {
            return detailIdx + 1;
        } else {
            return 0;
        }
    }

    function prevDetail() {
        if (detailIdx - 1 >= 0) {
            return detailIdx - 1;
        } else {
            return diagnosticsData[diagIdx].detail.length - 1;
        }
    }

    const handleDetailButtonClick = (
        item: {
            client: string;
            text: string;
            sum: string;
            detail: string[];
        },
        i: number
    ) => {
        setDiagIdx(i);
        setDetailIdx(0);
        setDetailPath(item.detail[0]);
        setDetailModal(true);
    };

    let publicUrl = process.env.PUBLIC_URL;

    return (
        <>
            <div id="diagnostics" className="diagnosticsSection">
                <div className="testimonial-section sbs-testimonial-section pd-bottom-160">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-6 pd-xl-5 order-lg-12 align-self-center ">
                                <div className="section-title style-two mb-0">
                                    <h2 className="title">Naše diagnostiky</h2>
                                    <p>
                                        Našu diagnostiku využilo už viac ako 500
                                        spoločností, ktoré vďaka tomu znížili
                                        účty za elektrickú energiu a to nie
                                        jednorazovo, ale na stálo a môžu to
                                        vidieť každý mesiac keď im príde faktúra
                                        za elektrickú energiu.
                                    </p>
                                    <div className="diagValueBox">
                                        <Row gap="15px">
                                            <Col
                                                className="dvb-iconCol"
                                                justify="center"
                                            >
                                                <img
                                                    src={`${publicUrl}/assets/img/icons/average.png`}
                                                    alt="average"
                                                    width="50"
                                                    height="50"
                                                />
                                            </Col>
                                            <Col justify="center">
                                                <div className="dvb-title">
                                                    Priemerná úspora
                                                </div>
                                                <div className="dvb-sum">
                                                    Každý rok 680&nbsp;Eur
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="diagValueBox">
                                        <Row gap="15px">
                                            <Col
                                                className="dvb-iconCol"
                                                justify="center"
                                            >
                                                <img
                                                    src={`${publicUrl}/assets/img/icons/top.png`}
                                                    alt="top"
                                                    width="50"
                                                    height="50"
                                                />
                                            </Col>
                                            <Col justify="center">
                                                <div className="dvb-title">
                                                    Najvyššia úspora
                                                </div>
                                                <div className="dvb-sum">
                                                    Každý rok
                                                    12&nbsp;000&nbsp;Eur
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div className="sbs-testimonial-slider">
                                    <div className="choose_slider">
                                        <div className="choose_slider_items">
                                            <ul id="testimonial-slider">
                                                {(diagnosticsData || []).map(
                                                    (item, i) => (
                                                        <li
                                                            key={i}
                                                            className="current_item"
                                                        >
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <h6>
                                                                        {
                                                                            item.client
                                                                        }
                                                                    </h6>
                                                                    <br />
                                                                    <p className="designation">
                                                                        {
                                                                            item.text
                                                                        }
                                                                    </p>
                                                                    <span className="diagSum">
                                                                        {
                                                                            item.sum
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="diagButton button pink"
                                                                onClick={() =>
                                                                    handleDetailButtonClick(
                                                                        item,
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                Zobraziť analýzu
                                                            </button>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sbs-arrowleft">
                                        <a id="btn_next" href="/">
                                            <i className="fa fa-long-arrow-left"></i>
                                        </a>
                                    </div>
                                    <div className="sbs-arrowright">
                                        <a id="btn_prev" href="/">
                                            <i className="fa fa-long-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                height="85vw"
                padding="30px 0px"
                open={detailModal}
                closeHandler={() => setDetailModal(false)}
            >
                <Col align="center" className="diagModalWrapper">
                    {detailPath && (
                        <div className="diagModalImgContainer">
                            <img src={detailPath} alt="diagnostic_detail" />
                        </div>
                    )}
                    <Row
                        justify="center"
                        align="center"
                        className="diagModalBtnContainer"
                        gap="5px"
                    >
                        <Col width={1}></Col>
                        <Col width={1}>
                            <Row justify="center" align="center">
                                <button
                                    className="button diagModalBtn mr5"
                                    onClick={() => {
                                        const prevIdx = prevDetail();
                                        setDetailIdx(prevIdx);
                                        setDetailPath(
                                            diagnosticsData[diagIdx].detail[
                                                prevIdx
                                            ]
                                        );
                                    }}
                                >
                                    &lt;
                                </button>
                                <div className="diagModalPage">
                                    {detailIdx + 1}/
                                    {diagnosticsData[diagIdx].detail.length}
                                </div>
                                <button
                                    className="button diagModalBtn ml5"
                                    onClick={() => {
                                        const nextIdx = nextDetail();
                                        setDetailIdx(nextIdx);
                                        setDetailPath(
                                            diagnosticsData[diagIdx].detail[
                                                nextIdx
                                            ]
                                        );
                                    }}
                                >
                                    &gt;
                                </button>
                            </Row>
                        </Col>
                        <Col width={1} align="flex-end">
                            <button
                                className="button diagModalCloseBtn"
                                onClick={() => setDetailModal(false)}
                            >
                                Zavrieť
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Modal>
        </>
    );
};

export default Diagnostics;
