import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import Row from "../../Row";
import Col from "../../Col";
import TextAreaInput, {
    TextAreaInputProps,
} from "../../energeticProtocolForm/inputs/TextAreaInput";
import Input from "../../energeticProtocolForm/Input";
import { config } from "../../../config";
import { sendEmail } from "../../../helpers/fetchHelpers";

const NonExistingOrderError = () => {
    const history = useHistory();
    const [message, setMessage] = useState("");
    const [showSupportMsgInput, setShowSupportMsgInput] = useState(false);
    const params = useParams<{ uuid: string }>();
    const uuid = params.uuid;
    const publicUrl = process.env.PUBLIC_URL;

    const sendEmailToSupport = () => {
        uuid &&
            sendEmail(
                uuid,
                config.emailTypeCodes.support_contact_email,
                message
            )
                .then(() => {
                    setShowSupportMsgInput(false);
                    toast(
                        "Vaša pripomienka bola odoslaná. Naši pracovníci Vás budú kontaktovať pre vyriešenie daného problému."
                    );
                })
                .catch(() => {
                    toast(
                        "Pri odosielaní formulára nastala chyba. Skúste odoslať formulár znova alebo nám napíšte na email: helpdesk@endia.sk"
                    );
                });
    };

    return (
        <div className="messageBox">
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/fail.png`}
                    alt="fail"
                    width="70"
                    height="70"
                />
                <div className="messageBoxItem">
                    <h2>Nastala chyba</h2>
                </div>
            </Row>
            <div className="messageBoxInfo">
                <p>
                    <span className="fontW400">
                        Stránka so zadanou adresou, alebo unikátnym číslom
                        objednávky neexistuje.
                    </span>
                </p>
                <br />
                <ul>
                    <li>
                        Ak ste na túto stránku prišli pomocou odkazu na nahratie
                        súborových podkladov pre analýzu, prosím kontaktujte
                        našu podporu, aby sme mohli nájsť riešenie problému.
                    </li>
                    <br />
                    <li>
                        Ak ste sa na stránku dostali náhodne, prosím prejdite na
                        domovskú stránku Endia.sk .
                    </li>
                </ul>
            </div>
            <Row className="formButtonContainer" gap="5px" wrap="wrap">
                <Col width={1}>
                    <button
                        className="button"
                        onClick={() => history.push("/")}
                    >
                        Prejsť na domovskú stránku
                    </button>
                </Col>
                <Col width={1}>
                    <button
                        className="button"
                        onClick={() =>
                            setShowSupportMsgInput(!showSupportMsgInput)
                        }
                    >
                        {showSupportMsgInput
                            ? "Zavrieť kontaktný formulár"
                            : "Kontaktovať podporu"}
                    </button>
                </Col>
            </Row>
            {showSupportMsgInput && (
                <Col className="contactSupportContainer">
                    <span className="fontW400">KONTAKTOVAŤ PODPORU:</span>
                    <Input<TextAreaInputProps>
                        component={TextAreaInput}
                        label=""
                        id="supportMsg"
                        placeholder="Tu popíšte problém, s ktorým by Vám naši pracovníci mohli pomôcť"
                        value={message}
                        onChange={(val) => setMessage(val)}
                    />
                    <button
                        className="button supportMsgBtn"
                        onClick={() => sendEmailToSupport()}
                    >
                        Odoslať správu
                    </button>
                </Col>
            )}
        </div>
    );
};

export default NonExistingOrderError;
