import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Row from "../../Row";
import Col from "../../Col";

const CompletedOrderMessage = ({ number }) => {
    const history = useHistory();
    const publicUrl = process.env.PUBLIC_URL;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="messageBox">
            <h2>Ďakujeme za ukončenie celého procesu objednávky</h2>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/success.png`}
                    alt="success"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Vaša objednávka č. {number} bola úspešne dokončená.
                </div>
            </Row>
            <Row>
                <div className="messageBoxItemDescription">
                    <p>
                        Po skontrolovaní Vašich údajov a priložených súborov,
                        bude nahraté faktúry analyzovať náš energetik.
                    </p>
                    <p>
                        Do 5 pracovných dní Vám bude na e-mail doručený pdf
                        protokol s výstupom analýzy a optimalizačnými
                        odporúčaniami.
                    </p>
                </div>
            </Row>
            <Row className="formButtonContainer" gap="5px">
                <Col width={1}>
                    <button
                        className="button"
                        onClick={() => history.push("/")}
                    >
                        Prejsť na domovskú stránku
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default CompletedOrderMessage;
