import React, { FC, useContext, useEffect, useState } from "react";

import { postData } from "../../helpers/fetchHelpers";
import { config } from "../../config";
import Input from "./Input";
import Col from "../Col";
import Row from "../Row";
import CheckboxInput, { CheckboxInputProps } from "./inputs/CheckboxInput";
import { AppContext } from "../../App";
import Tooltip from "../Tooltip";
import RadioGroup, { RadioGroupProps } from "./inputs/RadioGroup";
import StepProgress from "./StepProgress";
import { calculatePeriodEnd, formatPeriodDate } from "../../helpers/helpers";
import { clearCookie } from "../../helpers/cookiesHelpers";
import { Link } from "react-router-dom";
import Preloader from "../Preloader";

interface Props {
    onMount: () => void;
    onBack: () => void;
}

const FormStep3: FC<Props> = ({ onMount, onBack, ...props }) => {
    const {
        values,
        setValuesByKeys,
        clearValues,
        errors,
        setErrorByKey,
        uuid,
        step,
        setErrors,
    } = useContext(AppContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        onMount();
    }, [onMount]);

    const handleSubmit = () => {
        setLoading(true);
        uuid &&
            postData(uuid, values, step).then((res) => {
                if (res === "success") {
                    clearCookie("uuid");
                    clearValues();
                    window.location.href = `${config.server.url}/payment/order/${uuid}/pay`;
                } else {
                    setLoading(false);
                    setErrors(res);
                }
            });
    };

    const handleBack = () => onBack();

    function formatNumber(val: number | null) {
        return val
            ? val
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  .replace(".", ",")
            : "";
    }

    const periodEndDate = calculatePeriodEnd(values.period, values.periodStart);
    const periodEnd = formatPeriodDate(periodEndDate);
    const periodStart = `${values.periodStart.split("-")[1]}-${
        values.periodStart.split("-")[0]
    }`;

    return (
        <>
            {loading ? (
                <Preloader show={loading} />
            ) : (
                <>
                    <StepProgress onPrevStepClick={handleBack} />
                    <div className="formStep3Container">
                        <Row>
                            <Col width={2}>Názov spoločnosti:</Col>
                            <Col width={3}>
                                <b>{values.companyName}</b>
                            </Col>
                        </Row>
                        <div className="paymentSection">Kontaktná osoba:</div>
                        <Row>
                            <Col width={2}>Meno a priezvisko:</Col>
                            <Col width={3}>
                                <b>{values.fullname}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Emailová adresa:</Col>
                            <Col width={3}>
                                <b>{values.email}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Telefónne číslo:</Col>
                            <Col width={3}>
                                <b>{values.phone}</b>
                            </Col>
                        </Row>
                        <div className="paymentSection">
                            Údaje o spoločnosti:
                        </div>
                        <Row>
                            <Col width={2}>IČO spoločnosti:</Col>
                            <Col width={3}>
                                <b>{values.companyIco}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>DIČ:</Col>
                            <Col width={3}>
                                <b>{values.dic}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>IČ DPH:</Col>
                            <Col width={3}>
                                <b>{values.icdph}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Adresa - Ulica, číslo:</Col>
                            <Col width={3}>
                                <b>{values.street}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Obec:</Col>
                            <Col width={3}>
                                <b>{values.city}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>PSČ:</Col>
                            <Col width={3}>
                                <b>{values.postalCode}</b>
                            </Col>
                        </Row>
                        <div className="paymentSection">
                            Údaje o objednávke:
                        </div>
                        <Row>
                            <Col width={2}>Predmet:</Col>
                            <Col width={3}>
                                <b>
                                    Diagnostika účtovaných položiek za elektrinu
                                    - vypracovanie protokolu energetickej
                                    diagnostiky
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>EIC:</Col>
                            <Col width={3}>
                                <b>{values.companyEic}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Posudzované obdobie:</Col>
                            <Col width={3}>
                                <b>
                                    od {periodStart} do {periodEnd} (
                                    {values.period}
                                    &nbsp;mesiacov)
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Základná cena bez DPH:</Col>
                            <Col width={3}>
                                <b>{formatNumber(values.price)} Eur</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Poskytnutá zľava:</Col>
                            <Col width={3}>
                                <b>
                                    {values.calculatedDiscount
                                        ? "-" +
                                          formatNumber(
                                              values.calculatedDiscount
                                          ) +
                                          " "
                                        : "0,00 "}
                                    Eur
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Výsledná cena bez DPH:</Col>
                            <Col width={3}>
                                <b>
                                    {formatNumber(values.discountedPrice)} Eur
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Výsledná cena s DPH:</Col>
                            <Col width={3}>
                                <b>
                                    {formatNumber(values.discountedVatPrice)}{" "}
                                    Eur
                                </b>
                            </Col>
                        </Row>
                        <Row align="center">
                            <Col width={2}>Spôsob platby *</Col>
                            <Col width={3}>
                                <Input<RadioGroupProps<string>>
                                    component={RadioGroup}
                                    errors={errors.paymentMethod}
                                    options={[
                                        {
                                            label: (
                                                <>
                                                    Platobná karta
                                                    <img
                                                        className="payCardImg"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/assets/img/paymentCards/VISA.png"
                                                        }
                                                        alt="VISA"
                                                        width="50"
                                                        height="35"
                                                    />
                                                    <img
                                                        className="payCardImg"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/assets/img/paymentCards/VISAelectron.png"
                                                        }
                                                        alt="VISAelectron"
                                                        width="50"
                                                        height="35"
                                                    />
                                                    <img
                                                        className="payCardImg"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/assets/img/paymentCards/mastercard.png"
                                                        }
                                                        alt="MasterCard"
                                                        width="50"
                                                        height="35"
                                                    />
                                                    <img
                                                        className="payCardImg"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/assets/img/paymentCards/maestro.png"
                                                        }
                                                        alt="Maestro"
                                                        width="50"
                                                        height="35"
                                                    />
                                                    <img
                                                        className="payCardImg"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/assets/img/paymentCards/dinersClub.png"
                                                        }
                                                        alt="Maestro"
                                                        width="45"
                                                        height="35"
                                                    />
                                                </>
                                            ),
                                            value: "order_payment_card",
                                        },
                                    ]}
                                    name="paymentMethod"
                                    value={values.paymentMethod}
                                    onChange={(val) => {
                                        setValuesByKeys(
                                            ["paymentMethod"],
                                            [val]
                                        );
                                        setErrorByKey(
                                            "paymentMethod",
                                            undefined
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col width={2}>Dodanie protokolu:</Col>
                            <Col width={3}>
                                <b>
                                    Do e-mailovej schránky objednávateľa, formát
                                    pdf
                                </b>
                            </Col>
                        </Row>
                        <Input<CheckboxInputProps>
                            errors={errors.businessConditionsAgreement}
                            label={
                                <>
                                    Súhlasím so{" "}
                                    <Link to={`/vop`} target="_blank">
                                        Všeobecnými obchodnými podmienkami
                                    </Link>
                                    <Tooltip>
                                        Pre pokračovanie v objednávke je
                                        potrebné oboznámiť sa so Všeobecnými
                                        obchodnými podmienkami a súhlasiť s
                                        nimi. Ich text sa po kliku na odkaz
                                        zobrazí v novom okne prehliadača.
                                    </Tooltip>
                                </>
                            }
                            labelPlacement="end"
                            component={CheckboxInput}
                            id="businessConditionsAgreement"
                            value={values.businessConditionsAgreement}
                            onChange={(val) =>
                                setValuesByKeys(
                                    ["businessConditionsAgreement"],
                                    [val]
                                )
                            }
                        />
                    </div>
                    <Row className="formButtonContainer" gap="5px" wrap="wrap">
                        <Col width={1}>
                            <button className="button" onClick={handleBack}>
                                Predchádzajúci krok
                            </button>
                        </Col>
                        <Col width={1}>
                            <button
                                className="button pink"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                Záväzne objednať a prejsť na platbu
                            </button>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default FormStep3;
