import { addDays, addYears } from "date-fns";

import { config } from "../config";

const setCookie = (key: string, value: any) => {
    const now = new Date();
    const cks = (config.cookies as any)[key];
    let expiryDate;
    switch (cks.expiryUnit) {
        case "day":
            expiryDate = addDays(now, cks.expiryValue);
            break;
        case "year":
            expiryDate = addYears(now, cks.expiryValue);
            break;
        default:
            expiryDate = now;
            break;
    }
    document.cookie = `${key}=${value}; expires=${expiryDate}; path=/`;
};

const clearCookie = (key: string) => {
    document.cookie = `${key}=null; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

const readCookie = (key: string) => {
    const nameEQ = key + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export { setCookie, clearCookie, readCookie };
