interface ConfigServerType {
    url: string, 
    path: string, 
    apikey: string
};

export interface ConfigType {
    server: ConfigServerType;
    cookies: {
        uuid: {
            expiryValue: number, 
            expiryUnit: string
        }, 
        cookiesAgreement: {
            expiryValue: number, 
            expiryUnit: string
        }
    }
    recaptcha: {
        sitekey: string
    };
    fileUpload: {
        fileMaxSize: number
    };
    emailTypeCodes: {
        support_contact_email: number, 
        repreat_payment_email: number, 
        files_upload_email: number
    };
}

const API_URL = process.env.REACT_APP_API_URL as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

const config: ConfigType = {
    server: {
        url: API_URL,
        path: "/api/v1",
        apikey: "someapikey"
    },
    cookies: {
        uuid: {
            expiryValue: 1,
            expiryUnit: "day"
        },
        cookiesAgreement: {
            expiryValue: 1,
            expiryUnit: "year"
        }
    },
    recaptcha: {
        sitekey: "6LceSAAVAAAAAPY-zzORvOq_G3FZj7AI25zyZdDx"
    },
    fileUpload: {
        fileMaxSize: 50000000
    },
    emailTypeCodes: {
        support_contact_email: 311,
        repreat_payment_email: 312,
        files_upload_email: 313
    }
};

export { config };