import React, { FC } from "react";
import cn from "classnames";

import "../assets/icomoon/style.css";

interface Props {
    name: string;
    size?: string;
    color?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    onMouseOver?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const Icon: FC<Props> = ({
    name,
    size,
    color,
    className,
    onClick,
    onMouseOver,
    onMouseLeave,
    ...props
}) => {
    return (
        <span
            className={cn(`icon icon-${name}`, className)}
            style={{
                ...(size && { fontSize: size }),
                color,
                cursor: onClick ? "pointer" : "auto",
            }}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        />
    );
};

export default Icon;
