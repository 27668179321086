import React, { FC, useState } from "react";
import cn from "classnames";

import Icon from "./Icon";

type VisibilityType =
    | "hidden"
    | "visible"
    | "-moz-initial"
    | "inherit"
    | "initial"
    | "revert"
    | "unset"
    | "collapse"
    | undefined;

interface Props {
    iconColor?: string;
    iconFontSize?: string;
    classNames?: string;
}

const Tooltip: FC<Props> = ({
    iconColor,
    iconFontSize,
    classNames,
    ...props
}) => {
    const [visibility, setVisibility] = useState<VisibilityType>("hidden");
    return (
        <>
            <Icon
                name="info-light"
                color={iconColor}
                size={iconFontSize}
                className={cn("customTooltipIcon", classNames)}
                onMouseOver={(e) => setVisibility("visible")}
                onMouseLeave={(e) => setVisibility("hidden")}
            />
            <div
                className="customTooltip"
                style={{ visibility }}
                onMouseOver={(e) => setVisibility("visible")}
                onMouseLeave={(e) => setVisibility("hidden")}
            >
                {props.children}
            </div>
        </>
    );
};

export default Tooltip;
