import React from "react";
import cn from "classnames";

type Props<T> = T & {
    component: React.ComponentType<T>;
    containerClassNames?: string;
    errors?: string[];
    children?: React.ReactNode;
};

function Input<T>(props: Props<T>) {
    const Component = props.component;
    return (
        <div
            className={cn(
                "inputContainer",
                props.containerClassNames,
                props.errors && props.errors.length ? "error" : ""
            )}
        >
            <Component {...props} />
            <div className="inputErrorMessage">
                {props.errors?.map((errorMessage, i) => (
                    <div key={i}>{errorMessage}</div>
                ))}
            </div>
        </div>
    );
}

export default Input;
