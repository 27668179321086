import React from "react";
import { useLocation } from "react-router-dom";

import Row from "../../Row";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import Col from "../../Col";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFail from "./PaymentFail";

const PostPaymentPage = () => {
    const paymentCode = new URLSearchParams(useLocation().search).get(
        "payment_code"
    );
    const publicUrl = process.env.PUBLIC_URL;

    return (
        <>
            <Navbar showMenuItems={false} orderButton="hidden" />
            <div className="pd-top-120">
                <Row wrap="wrap">
                    <Col
                        className="order-image-bg wow animate fadeInLeft"
                        justify="flex-end"
                        align="flex-end"
                    >
                        <div className="order-image-wrapper">
                            <img
                                src={`${publicUrl}/assets/img/man-working-home.jpg`}
                                alt="order"
                            />
                        </div>
                    </Col>
                    <Col className="orderForm wow animate fadeInRight">
                        {paymentCode === "0" ? (
                            <PaymentSuccess />
                        ) : (
                            <PaymentFail />
                        )}
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    );
};

export default PostPaymentPage;
