import React, { useState, FC } from "react";

import { setCookie } from "../helpers/cookiesHelpers";
import Row from "./Row";
import PopUpPanel from "./PopUpPanel";
import Col from "./Col";
import { Link } from "react-router-dom";

interface Props {
    setCookiesAgreement: (val: boolean) => void;
}

const CookiesPanel: FC<Props> = ({ setCookiesAgreement, ...props }) => {
    const [openPopUpPanel, setOpenPopUpPanel] = useState(true);

    return (
        <>
            <PopUpPanel open={openPopUpPanel}>
                <Row
                    align="center"
                    className="cookiesAgreementPanel"
                    wrap="wrap"
                >
                    <Col width={1} className="tab bf">
                        Táto webstránka
                        <br />
                        používa cookies
                    </Col>
                    <Col width={4} className="tab big">
                        V záujme zlepšenia funkčnosti webovej stránky, sú na
                        tejto stránke používané súbory cookies. Pokračovaním v
                        prehliadaní tohto webu predpokladáme, že súhlasíte s
                        používaním súborov cookies.
                    </Col>
                    <Col className="moreInfoLinkTab tab bf" width={1}>
                        <Link to="/gdpr#cookies" target="_blank">
                            Viac informácií {">>"}
                        </Link>
                    </Col>
                    <Col className="tab btnTab" width={1}>
                        <button
                            onClick={(e) => {
                                setCookie("cookiesAgreement", true);
                                setCookiesAgreement(true);
                                setOpenPopUpPanel(false);
                            }}
                        >
                            SÚHLASÍM
                        </button>
                    </Col>
                </Row>
            </PopUpPanel>
        </>
    );
};

export default CookiesPanel;
