import React, { useEffect } from "react";
import DocumentMeta from "react-document-meta";

import Navbar from "../Navbar";
import Footer from "../Footer";

const GDPR = () => {
    
    useEffect(() => {
        window.location.hash === "" && window.scrollTo(0, 0);
    }, []);

    return (
        <DocumentMeta
            {...{
                title: "Energetická diagnostika - Ochrana osobných údajov",
            }}
        >
            <Navbar showMenuItems={false} closeButton={true} />
            <div className="blog-page-area pd-top-120">
                <div className="container">
                    <div className="row custom-gutters-60">
                        <div className="gdpr-vop-container col-lg-12">
                            <h2>Ochrana osobných údajov</h2>
                            <h3>Základné informácie</h3>
                            <h4>Správca osobných údajov</h4>
                            <p>
                                Sme právnická osoba ENERGO AUDIT, s.r.o., so
                                sídlom Kozmonautov 3/A, 949 01 Nitra, IČO: 50
                                341 120, DIČ: 2120 290 579, IČ DPH: SK2120 290
                                579, ktorá je zapísaná v Obchodnom registri
                                Okresného súdu Nitra, oddiel: Sro, vložka číslo:
                                40944/N, a sme správcom Vašich osobných údajov.
                            </p>
                            <h3>Aké osobné údaje spracovávame</h3>
                            <h4>Osobné údaje, ktoré nám poskytnete</h4>
                            <p>
                                Spracovávame osobné údaje, ktoré nám sami
                                poskytnete.
                            </p>
                            <h4>
                                Osobné údaje, ktoré získavame inak než priamo od
                                vás
                            </h4>
                            <p>
                                V rámci spoločných marketingových kampaní môžeme
                                vaše osobné údaje spracovávať aj my a pre účely
                                marketingových kampaní, prípadne pre účely
                                riadneho splnenia zmluvy a môžeme ich
                                kombinovať.
                            </p>
                            <p>
                                V prípade, že vaše osobné údaje budú predané
                                inému subjektu, budeme vás o tom vopred
                                informovať vrátane uvedenia toho, komu osobné
                                údaje predávame. Taktiež ak niekto predá vaše
                                osobné údaje nám, musí vás o tom rovnako vopred
                                informovať.
                            </p>
                            <h4>
                                Osobné údaje tretích osôb, ktoré nám poskytnete
                            </h4>
                            <p>
                                Ak nám poskytujete osobné údaje tretích osôb, je
                                vašou povinnosťou informovať o tom dotyčnú osobu
                                a zaistiť si jej súhlas s týmito podmienkami
                                ochrany osobných údajov.
                            </p>
                            <h4>
                                Osobné údaje, ktoré spracovávame automaticky
                            </h4>
                            <p>
                                Pri návšteve našej webovej stránky o vás môžeme
                                zhromažďovať určité informácie, ako sú napr. IP
                                adresa, dátum a čas prístupu na našu webovú
                                stránku, informácie o vašom internetovom
                                prehliadači, operačnom systéme či nastavení
                                vášho jazyka. Môžeme taktiež spracovávať
                                informácie o vašom správaní na našich webových
                                stránkach, t.j. napr. aké odkazy na našich
                                webových stránkach navštívite a ktorý tovar je
                                vám zobrazený. Informácie o vašom správaní na
                                webe sú však z dôvodu vášho maximálneho súkromia
                                anonymizované, a preto ich ani sami nedokážeme
                                priradiť konkrétnemu užívateľovi, tj. konkrétnej
                                osobe.
                            </p>
                            <p>
                                Ak pristupujete na naše webové stránky z
                                mobilného telefónu či obdobného prístroja alebo
                                prostredníctvom niektorej z našich mobilných
                                aplikácií, môžeme spracovávať aj informácie o
                                vašom mobilnom zariadení (dáta o vašom mobilnom
                                telefóne, prípadné záznamy o zlyhaní aplikácie a
                                pod).
                            </p>
                            <h4 id="cookies" className="gdprCookiesTitle">
                                Cookies
                            </h4>
                            <p>
                                Stránka využíva tzv. cookies. Sú to malé textové
                                súbory, ktoré sa na počítači dotknutej osoby
                                ukladajú do špeciálnej schránky prehliadača.
                                Vďaka ich využívaniu, ktoré je však anonymné, je
                                možné plnohodnotne využívať všetky funkcie
                                Stránky. Cookies uľahčujú tak používanie Stránky
                                a zlepšujú jej výkon a funkčnosť. Ide najmä o
                                sledovanie správania používateľa a následné
                                prispôsobenie obsahu, zjednodušenie používania
                                Stránky, možnosť prihlasovania, prispôsobenie a
                                obmedzenie reklamných kampaní a podobné
                                funkcionality, ktoré by bez využitia cookies
                                neboli možné.
                            </p>
                            <ol>
                                <li>
                                    Dotknutá osoba má právo zmenou nastavenia vo
                                    svojom internetovom prehliadači, samozrejme,
                                    využívanie cookies zakázať. Ak dotknutá
                                    osoba neakceptuje žiadne cookies, nebude
                                    môcť využívať kompletnú funkčnosť a všetky
                                    ponuky Stránky.
                                </li>
                                <li>
                                    Poskytovateľ je oprávnený sledovať a
                                    uchovávať IP adresy užívateľov – klientov, a
                                    to výhradne pre účely evidencie prístupu na
                                    Stránku. IP adresy nie sú v žiadnom prípade
                                    poskytované tretím osobám a na ich
                                    zabezpečenie používa Poskytovateľ všetky
                                    potrebné bezpečnostné opatrenia podľa
                                    interného bezpečnostného projektu. V rámci
                                    tohto procesu je IP adresa užívateľa -
                                    klienta zaznamenaná iba v skrátenej podobe,
                                    identifikovať konkrétne pripojenie tak nie
                                    je v žiadnom prípade možné. Identita
                                    užívateľa - klienta teda zostáva neznáma.
                                    Užívateľ má právo na informácie o použití IP
                                    adries.
                                </li>
                                <li>
                                    Poskytovateľ zaznamenáva aj informácie,
                                    ktoré poskytne internetový prehliadač
                                    dotknutej osoby v súvislosti s využívaním
                                    Stránky (okrem iného aj názov vyvolaného
                                    súboru, dátum a čas dopytu, množstvo
                                    prenesených údajov, hlásenie úspešného
                                    dopytu, typ internetového prehliadača, jazyk
                                    a doménu stránky, z ktorej sa dotknutá osoba
                                    na portál dostala), za účelom získania
                                    štatistických údajov o využívaní a
                                    efektívnosti Stránky. Poskytovateľ
                                    registruje každý prístup na Stránku a každý
                                    dopyt na umiestnené súbory.
                                </li>
                            </ol>
                            <h4>
                                Verzie pre mobilné telefóny a mobilné aplikácie
                            </h4>
                            <p>
                                Ak vstupujete na naše webové stránky z telefónu,
                                tabletu alebo podobného zariadenia, tak ich pre
                                tieto prístroje optimalizujeme. Vaše osobné
                                údaje v takom prípade spracovávame obdobným
                                spôsobom, ako v prípade prístupu z počítača.
                            </p>
                            <h3>
                                Prečo zhromažďujeme a spracovávame vaše osobné
                                údaje
                            </h3>
                            <p>
                                Vaše osobné údaje spracovávame z týchto dôvodov:
                            </p>
                            <ul>
                                <li>
                                    Nákup tovarov a služieb, poskytovanie
                                    služieb: v prvom rade spracovávame vaše
                                    osobné údaje k tomu, aby sme riadne vybavili
                                    a doručili vašu objednávku. Ak nastanú
                                    akékoľvek problémy, vďaka vašim osobným
                                    údajom vieme, na koho sa môžeme obrátiť.
                                </li>
                                <li>
                                    Starostlivosť o zákazníkov: ak sa na nás
                                    obrátite s nejakým dotazom / problémom,
                                    musíme k jeho zodpovedaniu / vyriešeniu
                                    spracovávať vaše údaje. V niektorých
                                    prípadoch môžu byť osobné údaje odovzdané aj
                                    tretím subjektom (napr. dopravcovi tovaru).
                                </li>
                                <li>
                                    Užívateľský účet: vďaka osobným údajom,
                                    ktoré nám poskytnete vo svojom užívateľskom
                                    profile, sa vám sprístupní rad užitočných
                                    funkcií (napr. ak uvediete svoje tel. číslo,
                                    môžeme vás ľahko informovať o tom, kedy vám
                                    bude objednávka doručená). Vami zadané údaje
                                    pritom môžete kedykoľvek zmeniť, a to s
                                    výnimkou e-mailovej adresy, ktorá slúži pre
                                    prístup k vášmu užívateľskému účtu.
                                </li>
                                <li>
                                    Marketingová činnosť:
                                    <ul>
                                        <li>
                                            E-mail marketing: e-mailové obchodné
                                            oznámenia vám zasielame na základe
                                            vášho súhlasu. Z odberu obchodných
                                            oznámení sa môžete ľahko odhlásiť
                                            tým, že si upravíte nastavenia
                                            svojho užívateľského profilu. Ak si
                                            vytvoríte viac užívateľských
                                            profilov, v rámci ktorých uvediete
                                            rovnaké kontaktné údaje (napr. máte
                                            niekoľko účtov pre rôzne e-mailové
                                            adresy, ale telefónne číslo bude na
                                            všetkých účtoch rovnaké), nie je
                                            možné z technických dôvodov vykonať
                                            odhlásenia z odberu obchodných správ
                                            pre všetky užívateľské profily na
                                            základe automatizovaných procesov.
                                        </li>
                                        <li>
                                            Telemarketing: marketingové hovory
                                            vykonávame za účelom ponuky našich
                                            tovarov a služieb a s tým súvisiacej
                                            marketingovej komunikácie. Právnym
                                            titulom pre spracovanie vášho tel.
                                            čísla je buď váš súhlas, alebo aspoň
                                            náš oprávnený záujem na konvenčnom
                                            priamom marketingu. Proti tomuto
                                            spracovaniu môžete namietať (pozri
                                            stránka kontakty> ostatné> osobné
                                            údaje> vznesenie námietky).
                                        </li>
                                        <li>
                                            Marketingové súťaže: v niektorých
                                            prípadoch môže byť výherca súťaže
                                            vyfotený, popr. natočený, a to najmä
                                            z dôvodu zvýšenia transparentnosti
                                            našich marketingových súťaží. Toto
                                            spracovanie osobných údajov
                                            vykonávame na základe nášho
                                            oprávneného záujmu, ktorý spočíva vo
                                            zvýšení vierohodnosti marketingových
                                            súťaží v očiach ostatných
                                            súťažiacich a vo zvýšení atraktivity
                                            týchto súťaží. Proti tomuto
                                            spracovaniu môžete namietať (pozri
                                            stránka kontakty> ostatné> osobné
                                            údaje> vznesenie námietky).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Zlepšovanie našich služieb: pomocou histórie
                                    vašich objednávok a správaniu na webe
                                    dokážeme ponúknuť relevantnejšie ponuky
                                    ďalšieho tovaru, napr. príslušenstvo k
                                    zakúpeným produktom. Na určitých miestach
                                    teda zobrazujeme také produkty, ktoré sú
                                    priamo pre vás a zodpovedajú vašim potrebám
                                    a záujmom. K optimalizácii prvkov na webe
                                    môžeme využívať aj nástroje pre testovanie
                                    rôznych variantov (tzv. A / B testovanie),
                                    Google Analytics, Facebook Analytics a pod.
                                </li>
                                <li>
                                    Zákaznícke hodnotenia tovaru a služieb:
                                    potom, čo u nás nakúpite tovar alebo služby,
                                    môžete byť požiadaní o ich ohodnotenie.
                                    Hodnotenie je možné vložiť aj z vlastnej
                                    iniciatívy.
                                </li>
                                <li>
                                    Call centrum: ak nás budete kontaktovať
                                    prostredníctvom nášho call centra
                                    (infolinka), budeme si váš telefónny hovor
                                    po predchádzajúcom upozornení nahrávať.
                                    Takéto záznamy nám slúžia ku kontrole
                                    kvality služieb poskytovaných našimi
                                    zamestnancami.
                                </li>
                                <li>
                                    Uplatnenie práv a právnych nárokov a
                                    kontroly orgánov verejnej moci: vaše osobné
                                    údaje môžeme taktiež spracovávať z dôvodu,
                                    že ich potrebujeme na uplatnenie našich práv
                                    a právnych nárokov (napr. v prípade, že voči
                                    nám budete mať neuhradenú pohľadávku). Ďalej
                                    môžeme vaše osobné údaje spracovávať aj z
                                    dôvodu, že ich potrebujeme na účely kontrol
                                    vykonávaných orgánmi verejnej moci a z
                                    ďalších obdobne závažných dôvodov.
                                </li>
                            </ul>
                            <h3>
                                Osobné údaje spracovávame na týchto právnych
                                podkladoch
                            </h3>
                            <h4>Plnenie a uzavretie zmluvy</h4>
                            <p>
                                Veľkú časť vašich osobných údajov potrebujeme k
                                tomu, aby sme s vami vôbec mohli uzavrieť kúpnu
                                či inú zmluvu ohľadom tovarov či služieb, ktoré
                                si u nás chcete zakúpiť. Akonáhle je zmluva
                                uzavretá, tak vaše osobné údaje spracovávame na
                                to, aby sme vám riadne doručili zakúpený tovar,
                                popr. aby sme vám riadne poskytli zakúpené
                                služby. Na základe tohto právneho dôvodu teda
                                spracovávame predovšetkým údaje fakturačné a
                                doručovacie.
                            </p>
                            <h4>Oprávnené záujmy</h4>
                            <p>
                                Vaše osobné údaje používame aj na to, aby sme
                                vám poskytli relevantný obsah, teda obsah, ktorý
                                je pre vás zaujímavý. Na základe oprávneného
                                záujmu takto spracovávame najmä osobné údaje,
                                ktoré spracovávame automaticky a cookies.
                            </p>
                            <p>
                                Z rovnakého právneho dôvodu vám ako našim
                                zákazníkom môžeme zasielať e-mailové a SMS
                                správy. Spracovanie vašich osobných údajov na
                                tomto právnom podklade sa deje aj pri
                                monitoringu vykonávanom prostredníctvom kamier
                                na našich jednotlivých prevádzkach.
                            </p>
                            <p>
                                Z dôvodu ochrany pred nelegálnym šírením a
                                sprístupňovaním vami zakúpenej elektronickej
                                knihy (či iného elektronického obsahu) je v
                                tejto elektronickej knihe zobrazené vaše meno,
                                priezvisko a vaša adresa.
                            </p>
                            <p>
                                Ak potom vaše osobné údaje spracovávame na
                                základe tohto právneho dôvodu, môžete proti
                                tomuto spracovaniu namietať (pozri stránka
                                kontakty> ostatné> osobné údaje> vznesenie
                                námietky).
                            </p>
                            <h4>Súhlas</h4>
                            <p>
                                Na účely zasielania obchodných oznámení (e-mail
                                marketing) a telemarketing spracovávame vaše
                                osobné údaje na základe vášho súhlasu. Ak nám
                                súhlas neudelíte a ste naším zákazníkom, tak vám
                                môžeme obchodné oznámenia zaslať (popr. vám v
                                rámci telemarketingu zavolať) aj bez toho, aby
                                ste nám udelili váš súhlas. V každom prípade
                                však platí, že môžete takúto marketingovú
                                komunikáciu z našej strany jednoducho zakázať
                                tým, že ju odmietnete.
                            </p>
                            <p>
                                O váš súhlas vás žiadame, aj keď si potrebujeme
                                overiť vašu schopnosť splácať tovar, ktorý vám
                                odovzdáme, bez toho aby ste za tovar vopred
                                uhradili kúpnu cenu v plnej výške.
                            </p>
                            <p>
                                Ak nám udelíte svoj súhlas so spracovaním
                                osobných údajov, môžete ho kedykoľvek odvolať
                                prostredníctvom nášho webového sídla
                                www.endia.sk.
                            </p>
                            <h3>
                                Odovzdávanie osobných údajov tretím subjektom
                            </h3>
                            <p>
                                V týchto prípadoch odovzdávame vaše osobné údaje
                                tretím subjektom:
                            </p>
                            <ul>
                                <li>
                                    <b>Doručenie tovaru:</b> vami zvolený
                                    dopravca by vám nikdy nebol schopný doručiť
                                    objednaný tovar, ak by sme mu oi.
                                    neodovzdali údaje, kam a komu konkrétne má
                                    tovar doručiť. Tieto údaje dopravcovi
                                    odovzdáme podľa toho, ako ich vyplníte v
                                    objednávke. Takto odovzdané údaje zahŕňajú
                                    predovšetkým vaše meno a priezvisko,
                                    doručovaciu adresu, tel. číslo, na ktorom
                                    vás môže dopravca kontaktovať a pokiaľ nebol
                                    tovar uhradený vopred, tak aj prípadne sumu,
                                    ktorú treba pri prevzatí tovaru uhradiť.
                                    Dopravca je vo vzťahu k osobným údajom,
                                    ktoré mu odovzdáme, oprávnený spracúvať ich
                                    len na účely doručenia tovaru a potom osobné
                                    údaje bezodkladne zmazať.
                                </li>
                                <li>
                                    <b>
                                        Doručenie tovaru, ktorý pre nás
                                        uskladňuje zmluvný partner:
                                    </b>
                                    ak si od nás objednávate tovar, ktorý sa
                                    nachádza v sklade nášho zmluvného partnera,
                                    musíme na dokončenie objednávky odovzdať
                                    vaše osobné údaje tomuto zmluvnému
                                    partnerovi, ktorý objednávku vybaví. Takto
                                    odovzdané údaje zahŕňajú predovšetkým vaše
                                    meno a priezvisko, doručovaciu adresu, tel.
                                    číslo, na ktorom vás môže dopravca
                                    kontaktovať a pokiaľ nebol tovar uhradený
                                    vopred, tak aj prípadne sumu, ktorú treba
                                    pri prevzatí tovaru uhradiť. Tento zmluvný
                                    partner potom uvedené osobné údaje musí
                                    odovzdať dopravcovi, ktorý bude tovar
                                    doručovať, pretože inak by vám dopravca
                                    nebol schopný tovar doručiť. Ako zmluvný
                                    partner, ktorý pre nás skladuje tovar, tak i
                                    dopravca, sú vo vzťahu k osobným údajom,
                                    ktoré im odovzdávame, povinní tieto osobné
                                    údaje použiť len na účely skladovania /
                                    doručenia tovaru a potom ich bezodkladne
                                    zmazať.
                                </li>
                                <li>
                                    <b>Platobné karty:</b> naša spoločnosť{" "}
                                    <b>nemá</b> údaje o platobných kartách,
                                    ktorými u nás platíte. Údajmi o vašich
                                    platobných kartách disponuje iba zabezpečená
                                    platobná brána a príslušná banková
                                    inštitúcia.
                                </li>
                                <li>
                                    Ak máte svoju platobnú kartu uloženú pre
                                    urýchlenie ďalších nákupov na našom
                                    internetovom obchode, disponujeme len
                                    základnými údajmi ako je napr. niekoľko
                                    prvých a posledných číslic platobnej karty.
                                    Ak chcete tieto údaje vymazať, stačí
                                    navštíviť nastavenia svojho užívateľského
                                    profilu a potom údaje o platobnej karte
                                    jednoducho zmazať.
                                </li>
                                <li>
                                    <b>Obchodné oznámenia:</b> v prípade
                                    rozosielania obchodných oznámení (napr.
                                    e-mailom či prostredníctvom SMS správy)
                                    alebo telemarketingu môžeme k rozosielke či
                                    telefónnym hovorom využiť tretí subjekt.
                                    Tento subjekt je viazaný povinnosťou
                                    mlčanlivosti a vaše osobné údaje nesmie
                                    použiť na žiadny ďalší účel.
                                </li>
                                <li>
                                    <b>Štátne orgány:</b> v prípade vymáhania
                                    našich práv môžu byť vaše osobné údaje
                                    odovzdané tretiemu subjektu (napr.
                                    advokátovi). Ak nám právny poriadok, alebo
                                    orgán štátnej moci (napr. PZ SR), uloží
                                    povinnosť, aby sme mu odovzdali vaše osobné
                                    údaje, musíme tak urobiť.
                                </li>
                            </ul>
                            <h3>Ako dlho vaše osobné údaje spracovávame?</h3>
                            <p>
                                V prvom rade budeme Vaše údaje spracovávať po
                                celú dobu trvania zmluvného vzťahu medzi nami.
                            </p>
                            <p>
                                V prípade spracovávania osobných údajov, ku
                                ktorému bol udelený súhlas, budú Vaše osobné
                                všeobecne spracovávané po dobu 7 rokov, alebo do
                                odvolania takého súhlasu.
                            </p>
                            <p>
                                V prípade, že sa prihlásite k odberu obchodných
                                správ, tak vaše osobné údaje budeme spracovávať
                                po dobu 7 rokov, popr. do doby vyslovenia vášho
                                nesúhlasu s ich ďalším zasielaním. Tento
                                nesúhlas môžete ľahko vyjadriť na našom webovom
                                sídle www.endia.sk.
                            </p>
                            <p>
                                Ďalej upozorňujeme, že tie osobné údaje, ktoré
                                sú nevyhnutné pre riadne poskytnutie služieb,
                                resp. pre splnenie všetkých našich povinností,
                                či už tieto povinnosti vyplývajú zo zmluvy medzi
                                nami či zo všeobecne záväzných právnych
                                predpisov musíme spracovávať bez ohľadu na Vami
                                udelený súhlas na dobu stanovenú príslušnými
                                právnymi predpismi či v súlade s nimi (napr. u
                                daňových dokladov je touto dobou doba najmenej
                                10 rokov).
                            </p>
                            <p>
                                Údaje získané prostredníctvom používateľského
                                účtu či iným obdobným spôsobom, spracovávame po
                                dobu využívania našich služieb a ďalej zvyčajne
                                5 rokov po ich zrušení. Následne sú zvyčajne
                                ukladané primeranú dobu iba základné
                                identifikačné údaje a údaj o tom, z akého dôvodu
                                bol užívateľský účet zrušený či údaje tvoriace
                                súčasť prevádzkových záloh.
                            </p>
                            <h3>Zabezpečenie osobných údajov</h3>
                            <p>
                                Vaše osobné údaje sú u nás v bezpečí. Aby sme
                                zamedzili neoprávnenému prístupu a zneužitiu
                                vašich osobných údajov, zaviedli sme primerané
                                opatrenia ako technického, tak organizačného
                                rázu.
                            </p>
                            <p>
                                Veľmi nám záleží na ochrane vašich osobných
                                údajov. Preto nielen pravidelne kontrolujeme ich
                                zabezpečenie, ale ich ochranu priebežne
                                zdokonaľujeme. Všetka komunikácia medzi vaším
                                zariadením a našimi webovými servermi je
                                šifrovaná. Prihlasovacie údaje sú zahashované a
                                všetky vaše dáta sú uložené len na serveroch v
                                zabezpečených dátových centrách s obmedzeným,
                                starostlivo kontrolovaným a auditovaným
                                prístupom.
                            </p>
                            <p>
                                Snažíme sa používať také bezpečnostné opatrenia,
                                ktoré s ohľadom na aktuálny stav technológií
                                poskytujú dostatočné zabezpečenie. Prijaté
                                bezpečnostné opatrenia sú potom pravidelne
                                aktualizované.
                            </p>
                            <h3>Zabezpečenie hesiel</h3>
                            <p>
                                Vaše heslá v systéme vôbec nemáme, neukladáme
                                ich ani do databázy. Pracujeme iba so špeciálne
                                prepočítavanými heslovacími kľúčmi, ktoré sú
                                vygenerované pomocou heslovacej funkcie.
                            </p>
                            <h3>Osobné údaje osôb mladších ako 16 rokov</h3>
                            <p>
                                Náš internetový obchod nie je určený pre deti
                                mladšie ako 16-rokov. Osoba mladšia ako 16-rokov
                                môže náš internetový obchod používať iba, ak na
                                to udelí súhlas jeho zákonný zástupca (rodič,
                                alebo poručník).
                            </p>
                            <h3>
                                Aké máte práva v súvislosti s ochranou Vašich
                                osobných údajov
                            </h3>
                            Vo vzťahu k vašim osobným údajom máte najmä právo
                            kedykoľvek odvolať svoj súhlas so spracovaním
                            osobných údajov, právo opraviť či doplniť svoje
                            osobné údaje, právo požadovať obmedzenie ich
                            spracovania, právo vzniesť námietku alebo sťažnosť
                            proti spracovávaniu vašich osobných údajov, právo na
                            prístup k vašim osobným údajom , právo požadovať
                            prenesenie vašich osobných údajov, právo byť
                            informovaný o porušení zabezpečenia vašich osobných
                            údajov a za určitých podmienok aj právo na vymazanie
                            niektorých osobných údajov, ktoré v súvislosti s
                            vami spracovávame (tzv. právo byť „zabudnutý“).
                            <h4>Úprava a doplnenie</h4>
                            <p>
                                Svoje osobné údaje máte pod kontrolou
                                predovšetkým prostredníctvom vášho užívateľského
                                účtu. Môžete tu mazať, resp. meniť základné
                                informácie o vašej osobe a meniť nastavenia
                                ohľadom zasielania obchodných oznámení (popr. sa
                                z odberu obchodných oznámení odhlásiť) a pod.
                            </p>
                            <p>
                                Prípadne nás môžete kontaktovať prostredníctvom
                                webového sídla www.endia.sk.
                            </p>
                            <h4>Oprava</h4>
                            <p>
                                V prípade, že sa domnievate, že osobné údaje,
                                ktoré o vás spracovávame, sú chybné, nás môžete
                                kontaktovať prostredníctvom webového sídla
                                www.endia.sk. Z dôvodu urýchlenia opravy vašich
                                osobných údajov a vášho komfortu však odporúčame
                                upraviť si svoje osobné údaje vo svojom
                                užívateľskom profile.
                            </p>
                            <h4>Prístup (portabilita)</h4>
                            <p>
                                Môžete nás požiadať, aby sme vám zaslali prehľad
                                vašich osobných údajov, a to prostredníctvom
                                webového sídla www.endia.sk.
                            </p>
                            <p>
                                Súčasne máte právo na prístup k týmto
                                informáciám týkajúcich sa vašich osobných
                                údajov:
                            </p>
                            <ul>
                                <li>
                                    Aké sú účely spracovania vašich osobných
                                    údajov
                                </li>
                                <li>
                                    Aké sú kategórie dotknutých osobných údajov
                                </li>
                                <li>
                                    Kto, okrem nás, sú príjemcami vašich
                                    osobných údajov
                                </li>
                                <li>
                                    Plánovaná doba, po ktorú budú vaše osobné
                                    údaje uložené
                                </li>
                                <li>
                                    Či máte právo požadovať od nás opravu alebo
                                    vymazanie vašich osobných údajov alebo
                                    obmedzenie ich spracovania alebo vzniesť
                                    námietku proti tomuto spracovaniu
                                </li>
                                <li>
                                    Informácie o zdroji osobných údajov, ak sme
                                    ich nezískali od vás
                                </li>
                            </ul>
                            <h4>Výmaz</h4>
                            <p>
                                Ďalej môžete žiadať, aby sme údaje o vás
                                vymazali (vymazanie sa však nedotkne údajov na
                                dokumentoch, ktoré musíme podľa zákona uchovávať
                                (napr. faktúry či dobropisy). Ak vaše osobné
                                údaje budeme potrebovať pre určenie, výkon alebo
                                obranu našich právnych nárokov, môže byť vaša
                                žiadosť odmietnutá (napr. keď za vami evidujeme
                                neuhradenú pohľadávku či v prípade
                                prebiehajúceho reklamačného konania).
                            </p>
                            <p>
                                Upozorňujeme, že hlavné informácie o vašej
                                platobnej karte nie sú ukladané v našej
                                spoločnosti, ale u našej platobnej brány. Preto
                                sa tieto dáta nedajú z našej strany vymazať a je
                                potrebné kontaktovať platobnú bránu, cez ktorú
                                ste platbu vykonávali.
                            </p>
                            <p>
                                S výnimkou prípadov uvedených máte právo na
                                výmaz v týchto prípadoch:
                            </p>
                            <ul className="listChars">
                                <li>
                                    Osobné údaje už nie sú potrebné na účely, na
                                    ktoré boli spracovávané
                                </li>
                                <li>
                                    Odvolal / a ste svoj súhlas, na základe
                                    ktorého boli údaje spracované a neexistuje
                                    žiadny ďalší právny dôvod pre ich
                                    spracovanie
                                </li>
                                <li>
                                    Vzniesol/a ste námietku proti spracovaniu
                                    os. údajov a máte za to, že pri posudzovaní
                                    námietky vyjde najavo, že v konkrétnej
                                    situácii prevažuje váš záujem nad naším
                                    záujmom na sprac. týchto os. údajov
                                </li>
                                <li>Osobné údaje sú spracované nezákonne</li>
                                <li>
                                    Povinnosť vymazania ustanovuje osobitný
                                    právny predpis
                                </li>
                                <li>
                                    Ide os. údaje detí mladších ako 16 rokov
                                </li>
                                <li>
                                    Svoje právo môžete uplatniť prostredníctvom
                                    webového sídla www.endia.sk.
                                </li>
                            </ul>
                            <h4>Vznesenie námietky</h4>
                            <p>
                                Niektoré vaše osobné údaje spracovávame na
                                základe nášho oprávneného záujmu (pozri kapitolu
                                týchto podmienok nazvaná "Osobné údaje
                                spracovávame na týchto právnych podkladoch").
                                Pokiaľ na vašej strane budú existovať konkrétne
                                dôvody, môžete namietať proti spracovávaniu
                                týchto vašich osobných údajov. Túto námietku
                                môžete vzniesť pomocou webového sídla
                                www.endia.sk.
                            </p>
                            <h4>Obmedzenie spracovania</h4>
                            <p>
                                Ak (a) popierate presnosť vašich osobných
                                údajov, (b) vaše osobné údaje sú spracovávané
                                nezákonne, (c) my už vaše osobné údaje
                                nepotrebujeme na účely spracovania, ale vy ich
                                potrebujete pre určenie, výkon alebo obranu
                                vašich právnych nárokov, alebo ak ste (d)
                                vzniesol / a námietku podľa predchádzajúceho
                                bodu, tak máte právo na to, aby sme obmedzili
                                spracovanie vašich osobných údajov.
                            </p>
                            <p>
                                V takom prípade môžeme vaše osobné údaje
                                spracovávať len s vaším súhlasom (s výnimkou
                                uloženia či zálohy predmetných osobných údajov).
                            </p>
                            <h4>Podanie sťažnosti</h4>
                            <p>
                                Ak máte za to, že vaše osobné údaje spracovávame
                                protiprávne, máte tiež právo podať sťažnosť
                                Úradu pre ochranu osobných údajov. Budeme však
                                radi, ak budete prešľapy riešiť najprv s nami.
                                Vždy nás môžete ľahko kontaktovať
                                prostredníctvom nášho kontaktného formulára
                                alebo prostredníctvom webového sídla
                                www.endia.sk.
                            </p>
                            <p>
                                Tieto Podmienky ochrany osobných údajov vrátane
                                ich súčastí sú platné a účinné od 22.06. 2020,
                                pričom sú k dispozícii elektronicky na
                                www.endia.sk.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </DocumentMeta>
    );
};

export default GDPR;
