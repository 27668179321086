import { config } from "../config";
import { FormState } from "../App";
import { ContactValues } from "../components/ContactModal";

function processDataForPost(data: FormState, step: number) {
    return {
        forCompanyConfirm: data.forCompanyConfirm,
        fullname: data.fullname,
        email: data.email,
        phone: data.phone,
        gdprAgree: data.gdprAgree,
        companyName: data.companyName,
        companyEic: data.companyEic,
        companyIco: data.companyIco,
        dic: data.dic,
        icdph: data.icdph,
        street: data.street,
        city: data.city,
        postalCode: data.postalCode,
        businessConditionsAgreement: data.businessConditionsAgreement,
        period: data.period,
        periodStart: data.periodStart,
        paymentMethod: data.paymentMethod,
        ...(step > 0
            ? { ["step" + (step + 1)]: true }
            : { previousStep: true }),
    };
}

function processFileData(fileData: any) {
    const fd = (fileData || []).map((f: any) => {
        return {
            uuid: f.uuid,
            name: f.name,
            timestamp: new Date(f._inserted_at).getTime(),
        };
    });
    return fd;
}

async function postData(
    uuid: string,
    data: FormState,
    step: number
): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/${uuid}/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
                body: JSON.stringify(processDataForPost(data, step)),
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (
                            response.status === 200 ||
                            response.status === 400
                        ) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (res.error) {
                    reject();
                }
                if (res.statusCode === 400) {
                    const r = Object.entries(res.body.errors.children).reduce(
                        (acc: any, entry: any) => {
                            if (entry[1].errors) {
                                acc[entry[0]] = entry[1].errors;
                            }
                            return acc;
                        },
                        {}
                    );
                    resolve(r);
                } else if (res.statusCode === 200) {
                    resolve("success");
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

async function getData(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(`${config.server.url}${config.server.path}/order/${uuid}/get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": config.server.apikey,
            },
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve({
                        orderNumber: res.body.number || "",
                        forCompanyConfirm:
                            res.body.for_company_confirm || false,
                        fullname: res.body.fullname || "",
                        email: res.body.email || "",
                        phone: res.body.phone || "",
                        gdprAgree: res.body.gdpr_agree || "",
                        companyName: res.body.company_name || "",
                        companyEic: res.body.company_eic || "",
                        companyIco: res.body.company_ico || "",
                        dic: res.body.dic || "",
                        icdph: res.body.icdph || "",
                        street: res.body.street || "",
                        city: res.body.city || "",
                        postalCode: res.body.postal_code || "",
                        businessConditionsAgreement:
                            res.body.business_conditions_agreement || false,
                        orderFiles: processFileData(res.body.order_files) || [],
                        period: res.body.period || "",
                        periodStart: res.body.period_start || "",
                        coupon:
                            res.body.coupon && res.body.coupon.code
                                ? res.body.coupon.code
                                : null,
                        couponRedeemed: res.body.coupon ? true : false,
                        price: res.body.price || "",
                        calculatedDiscount:
                            res.body.calculated_discount || null,
                        discountRate: res.body.discount_rate || null,
                        discountedPrice: res.body.discounted_price || null,
                        discountedVatPrice:
                            res.body.discounted_vat_price || null,
                        paymentMethod: res.body.payment_method || null,
                        status: res.body.status || null,
                        payedAt: res.body.payed_at || null,
                        createdAt: res.body.created_at || null,
                        createCompletedAt: res.body.create_completed_at || null,
                    });
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

// TODO: dorobit ked bude spraveny endpoint
async function getCompanyData(companyIco: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/${companyIco}/get`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((res: any) => {
                if (res.error) {
                    reject(res.error);
                } else {
                    resolve(res.company);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function uploadFile(uuid: string, f: any): Promise<any> {
    const fd = new FormData();
    fd.append("file", f, f.name);
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/file/${uuid}/upload`,
            {
                method: "POST",
                headers: {
                    "X-AUTH-TOKEN": config.server.apikey,
                },
                body: fd,
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (res.error) {
                    reject();
                } else {
                    resolve(res.body.insertedFiles[0]);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function deleteFile(fileId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/file/${fileId}/delete`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                // TODO: server side typo succes => success
                if (!res.error && res.body.sucess) {
                    resolve(res.body);
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function downloadFile(fileId: string, fileName: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/file/${fileId}/download`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.blob().then((blob) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: blob,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: null,
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(res.body);
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function redeemCoupon(
    uuid: string,
    coupon: string | null
): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/${uuid}/use-coupon`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
                body: JSON.stringify({ coupon }),
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error && res.body.valid) {
                    resolve("valid");
                } else {
                    resolve("invalid");
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getPrice(
    uuid: string,
    period: number | null,
    coupon: string | null
): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(`${config.server.url}${config.server.path}/order/${uuid}/price`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": config.server.apikey,
            },
            body: JSON.stringify({ period, coupon }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(res.body);
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getFiles(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(`${config.server.url}${config.server.path}/order/${uuid}/get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": config.server.apikey,
            },
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(processFileData(res.body.order_files) || []);
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function sendEmail(
    uuid: string,
    emailType: number,
    message?: string
): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/email/send/${uuid}/${emailType}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
                body:
                    emailType === config.emailTypeCodes.support_contact_email
                        ? JSON.stringify({ text: message || "" })
                        : "",
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function sendContactEmail(contactValues?: ContactValues): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(`${config.server.url}${config.server.path}/email/send/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": config.server.apikey,
            },
            body: JSON.stringify(contactValues),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function downloadProtocol(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            `${config.server.url}${config.server.path}/order/file/${uuid}/download-protocol`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-AUTH-TOKEN": config.server.apikey,
                },
            }
        )
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.blob().then((blob) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: blob,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: null,
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(res.body);
                } else {
                    reject();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

export {
    getData,
    postData,
    getCompanyData,
    uploadFile,
    deleteFile,
    downloadFile,
    redeemCoupon,
    getPrice,
    getFiles,
    sendEmail,
    sendContactEmail,
    downloadProtocol,
};
