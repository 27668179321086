import { addMonths, parseISO } from "date-fns";

function calculatePeriodEnd(period: number | null, periodStart: string | null) {
    if (period && periodStart && periodStart.length === 10) {
        return addMonths(new Date(periodStart), period - 1);
    }
    return null;
}

function formatPeriodDate(date: Date | null) {
    if (date === null) {
        return null;
    }
    const month = date.getMonth() + 1;
    let monthStr;
    if (month < 10) {
        monthStr = `0${month}`;
    } else {
        monthStr = `${month}`;
    }
    const year = date.getFullYear();
    return `${monthStr}-${year}`;
}

function formatPayedDate(dateStr: string | null) {
    if (dateStr === null) {
        return null;
    }
    const date = parseISO(dateStr);
    
    const day = date.getDate();
    let dayStr;
    
    if (day < 10) {
        dayStr = `0${day}`;
    } else {
        dayStr = `${day}`;
    }
    const month = date.getMonth() + 1;
    let monthStr;
    if (month < 10) {
        monthStr = `0${month}`;
    } else {
        monthStr = `${month}`;
    }
    const year = date.getFullYear();
    return `${dayStr}-${monthStr}-${year}`;
}

export { calculatePeriodEnd, formatPeriodDate, formatPayedDate };
