import React, { FC, useContext } from "react";

import Modal from "../Modal";
import Row from "../Row";
import { AppContext } from "../../App";

interface Props {
    open: boolean;
    closeHandler: () => void;
    submitBtnHandler?: (e: any) => void;
    cancelBtnHandler?: (e: any) => void;
}

const FilesUploadModal: FC<Props> = ({
    open,
    closeHandler,
    submitBtnHandler,
    cancelBtnHandler,
    ...props
}) => {
    const { values } = useContext(AppContext);
    const nextBtnTitle = "Pokračovať na ďalší krok";
    const backBtnTitle = "Späť a doplniť súbory";
    const publicUrl = process.env.PUBLIC_URL;

    return (
        <Modal
            open={open}
            closeHandler={closeHandler}
            height="525px"
            width="600px"
            submitBtnHandler={submitBtnHandler}
            submitBtnText={nextBtnTitle}
            cancelBtnHandler={cancelBtnHandler}
            cancelBtnText={backBtnTitle}
        >
            <div className="filesUploadModal">
                <Row align="center">
                    <img
                        src={`${publicUrl}/assets/img/icons/warning.png`}
                        alt="warning-icon"
                        width="50"
                        height="50"
                    />
                    <div className="title">UPOZORNENIE</div>
                </Row>
                <div className="hl" />
                <p>
                    <b>
                        Do formulára ste priložili menej ako {values.period}{" "}
                        súborov.
                    </b>
                </p>
                <p>
                    Ak priložené súbory obsahujú faktúry za zvolený počet po
                    sebe nasledujúcich mesiacov, stlačte tlačidlo{" "}
                    <b>{nextBtnTitle}</b>.
                </p>
                <p>
                    V opačnom prípade, prosím stlačte tlačidlo{" "}
                    <b>{backBtnTitle}</b> a doplňte požadované súbory. Inak
                    nebude možné energetickú diagnostiku spracovať.
                </p>
            </div>
        </Modal>
    );
};

export default FilesUploadModal;
