import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";

import Icon from "./Icon";
import Row from "./Row";
import Col from "./Col";

interface Props {
    open: boolean;
    width?: string;
    height?: string;
    padding?: string;
    classNames?: string;
    closeHandler: () => void;
    submitBtnHandler?: (e: any) => void;
    submitBtnText?: string;
    cancelBtnHandler?: (e: any) => void;
    cancelBtnText?: string;
    showCloseBtn?: boolean;
    fadeOutOnClose?: boolean;
}

const Modal: FC<Props> = ({
    open,
    width,
    height,
    padding,
    classNames,
    closeHandler,
    submitBtnHandler,
    submitBtnText,
    cancelBtnHandler,
    cancelBtnText,
    showCloseBtn = false,
    fadeOutOnClose = false,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [fadeOutAnim, setFadeOutAnim] = useState(false);

    const handleFadeOutAnimEnd = useCallback(() => {
        setFadeOutAnim(false);
    }, [setFadeOutAnim]);

    const first = useRef(true);
    useEffect(() => {
        ref.current && ref.current.scrollTo(0, 0);

        const contRef = containerRef.current;
        // fadeOut animation
        if (!first.current && !open && fadeOutOnClose) {
            if (contRef) {
                contRef.addEventListener(
                    "webkitAnimationEnd",
                    handleFadeOutAnimEnd
                );
                contRef.addEventListener("animationend", handleFadeOutAnimEnd);
            }
            setFadeOutAnim(true);
        } else {
            setFadeOutAnim(false);
        }

        if (first.current) {
            first.current = false;
        }

        return () => {
            if (contRef) {
                contRef.addEventListener(
                    "webkitAnimationEnd",
                    handleFadeOutAnimEnd
                );
                contRef.addEventListener("animationend", handleFadeOutAnimEnd);
            }
        };
    }, [open, fadeOutOnClose, handleFadeOutAnimEnd]);

    return (
        <div
            className="customModalContainer"
            style={{
                display: open || fadeOutAnim ? "block" : "none",
                ...(fadeOutAnim && {
                    animationName: "fadeOutModalAnimation",
                    animationDuration: "1s",
                }),
            }}
            ref={containerRef}
            onClick={(_) => closeHandler()}
        >
            <div
                className={cn("customModal", classNames)}
                onClick={(e) => e.stopPropagation()}
                ref={ref}
                style={{
                    maxWidth: width && width,
                    height: height && height,
                }}
            >
                {showCloseBtn && (
                    <Icon
                        name="cross"
                        className="customModalCloseIcon"
                        onClick={closeHandler}
                    />
                )}
                <div
                    className="customModalContent"
                    style={{ padding: padding && padding }}
                >
                    {props.children}
                    {(cancelBtnHandler || submitBtnHandler) && (
                        <Row
                            className="customModalButtonsContainer"
                            wrap="wrap"
                        >
                            {cancelBtnHandler && (
                                <Col className="customModalButtonCol">
                                    <button
                                        className="button"
                                        onClick={cancelBtnHandler}
                                    >
                                        {cancelBtnText || "Zrušiť"}
                                    </button>
                                </Col>
                            )}
                            {submitBtnHandler && (
                                <Col className="customModalButtonCol">
                                    <button
                                        className="button pink"
                                        onClick={submitBtnHandler}
                                    >
                                        {submitBtnText || "Odoslať"}
                                    </button>
                                </Col>
                            )}
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
