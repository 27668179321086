import React, { FC, useState, createRef, useEffect } from "react";
import cn from "classnames";

import Icon from "../../Icon";
import Col from "../../Col";
import Row from "../../Row";

export interface MonthpickerProps {
    value: string | null;
    label?: string | React.ReactNode;
    onChange: (val: string | null) => void;
    onBlur: () => void;
    months?: { num: string; name: string }[];
    minYear?: number;
    maxYear?: number;
    placeholder?: string;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
}

function generateYears(min: number, max: number) {
    return Array(max - min + 1)
        .fill(0)
        .map((_, i) => min + i);
}

function format(val: string | null, minYear: number, maxYear: number) {
    if (!val) {
        return null;
    }
    let v = val.replace(/[^0-9]/g, "");
    let m = "",
        y = "",
        sep = "";
    v = v.substr(0, 6);
    if (v.length < 2) {
        m = v;
    } else if (v.length === 2) {
        if (Number(v) < 1) {
            m = "01";
        } else if (Number(v) > 12) {
            m = "12";
        } else {
            m = v;
        }
    } else {
        m = v.substr(0, 2);
        sep = "-";
        y = v.substr(2, 4) || "";
        if (y.length === 4 && Number(y) < minYear) {
            y = "" + minYear;
        } else if (y.length === 4 && Number(y) > maxYear) {
            y = "" + maxYear;
        }
    }
    return m + sep + y;
}

function mask(value: string | null) {
    if (value === null || value === "") {
        return null;
    }
    const parts = value.split("-");
    const year = parts[0] || "";
    const month = parts[1] || "";
    return `${month}-${year}`;
}

function unmask(value: string | null) {
    if (value === null || value === "") {
        return null;
    }
    const parts = value.split("-");
    const month = parts[0] || "";
    const year = parts[1] || "";
    return `${year}-${month}-01`;
}

const Monthpicker: FC<MonthpickerProps> = ({
    onChange,
    onBlur,
    value,
    label,
    placeholder,
    minYear = 2000,
    maxYear = new Date().getFullYear(),
    inputRef,
    ...props
}) => {
    const [visible, setVisibile] = useState(false);
    const years = generateYears(minYear, maxYear);
    const months = props.months || [
        { num: "01", name: "Jan" },
        { num: "02", name: "Feb" },
        { num: "03", name: "Mar" },
        { num: "04", name: "Apr" },
        { num: "05", name: "Máj" },
        { num: "06", name: "Jún" },
        { num: "07", name: "Júl" },
        { num: "08", name: "Aug" },
        { num: "09", name: "Sep" },
        { num: "10", name: "Okt" },
        { num: "11", name: "Nov" },
        { num: "12", name: "Dec" },
    ];
    const [val, setVal] = useState(mask(value));
    const [year, setYear] = useState<number | null>(null);
    const [month, setMonth] = useState<string | null>(null);
    const yearRef = createRef<HTMLDivElement>();
    const yearTabRef = createRef<HTMLDivElement>();

    useEffect(() => {
        setVal(mask(value));
    }, [value]);

    useEffect(() => {
        !visible &&
            yearTabRef.current &&
            yearRef.current &&
            yearTabRef.current.scrollTo(
                0,
                yearRef.current.offsetTop - yearTabRef.current.clientHeight / 2
            );
    }, [visible, yearRef, yearTabRef]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = format(e.currentTarget.value, minYear, maxYear);
        const split = (v || "").split("-");
        setYear(Number(split[1]) || null);
        setMonth(split[0] || null);
        setVal(v);
        onChange(unmask(v));
    };

    const handlePickYear = (pickedYear: number) => {
        const newVal = `${month || "01"}-${pickedYear}`;
        setYear(pickedYear);
        setMonth(month || "01");
        setVal(newVal);
        onChange(unmask(newVal));
    };

    const handlePickMonth = (pickedMonth: string) => {
        const newVal = `${pickedMonth}-${year || new Date().getFullYear()}`;
        setMonth(pickedMonth);
        setYear(year || new Date().getFullYear());
        setVal(newVal);
        setVisibile(false);
        onChange(unmask(newVal));
    };

    return (
        <div>
            <label>{label}</label>
            <div>
                <Icon
                    name="calendar"
                    className="pickerIcon"
                    onClick={(e) => {
                        onBlur();
                        setVisibile(!visible);
                    }}
                />
                <input
                    type="text"
                    className="pickerInput"
                    value={val || ""}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    placeholder={placeholder || "MM-RRRR"}
                    pattern="^((0[1-9])|(1[0-2]))-([1-2]\d{3})$"
                    ref={inputRef}
                />
            </div>
            <div
                className={cn("pickerCloseTrigger", !visible ? "hidden" : "")}
                onClick={(e) => setVisibile(false)}
            />
            <Row
                className={cn("pickerTab", !visible ? "hidden" : "")}
                onClick={(e) => e.stopPropagation()}
            >
                <Col
                    className="pickerYearsTab"
                    align="center"
                    reff={yearTabRef}
                >
                    {years.map((y, key) => {
                        let currentYear = year;
                        if (year === null) {
                            currentYear = new Date().getFullYear();
                        }
                        return (
                            <div
                                key={key}
                                className={cn(
                                    "pickerYear",
                                    y === year ? "picked" : ""
                                )}
                                onClick={() => handlePickYear(y)}
                                ref={y === currentYear ? yearRef : undefined}
                            >
                                {y}
                            </div>
                        );
                    })}
                </Col>
                <Row className="pickerMonthsTab" wrap="wrap">
                    {months.map(({ num, name }, key) => (
                        <div
                            className={cn(
                                "pickerMonth",
                                num === month ? "picked" : ""
                            )}
                            key={key}
                            onClick={() => handlePickMonth(num)}
                        >
                            {name}
                        </div>
                    ))}
                </Row>
            </Row>
        </div>
    );
};

export default Monthpicker;
