import React, { FC } from "react";

interface Props {
    show: boolean;
    global?: boolean;
    bgColor?: string;
    bgStyle?: React.CSSProperties;
    loaderStyle?: React.CSSProperties;
}

const Preloader: FC<Props> = ({
    show,
    global = true,
    bgStyle,
    loaderStyle,
    bgColor,
}) => {
    return (
        <div
            className="preloaderBg"
            style={{
                display: show ? "block" : "none",
                position: global ? "fixed" : "absolute",
                backgroundColor: bgColor || "#272b2e",
                ...bgStyle,
            }}
        >
            <div className="preloader" style={loaderStyle} />
        </div>
    );
};

export default Preloader;
