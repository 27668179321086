import React from "react";

const Header = () => {
    const publicUrl = process.env.PUBLIC_URL;

    return (
        <div>
            <div className="headerContainer header-bg">
                <div className="headerVideoWrapper">
                    <video
                        autoPlay
                        muted
                        loop
                        className="headerVideo"
                        poster={`${publicUrl}/assets/img/header_video_img.jpg`}
                    >
                        <source
                            src={`${publicUrl}/assets/video/header_video.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className="container">
                    <div className="headerText">
                        <h1 className="title s-animate-1">
                            Zistite, ktoré položky za&nbsp;elektrinu Vám nemusia
                            byť účtované
                        </h1>
                        <p className="s-animate-2">
                            Platíte faktúry za elektrinu a neviete, za čo
                            platíte? Náš energetik vyhodnotí Vaše faktúry a
                            doručíme Vám analýzu, v ktorej Vás upozorníme na
                            nežiadúce položky, vypočítame možnú úsporu a
                            vypracujeme postup ako ju dosiahnuť.
                        </p>
                        <div className="headerButton btn-wrapper desktop-left padding-top-20">
                            <a
                                href="/#order"
                                className="headerLinkBtn btn btn-radius btn-green s-animate-3"
                            >
                                Chcem využiť služby energetika
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
