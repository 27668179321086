import React, { FC, useRef, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as generateUuid } from "uuid";
import { toast } from "react-toastify";

import {
    uploadFile,
    deleteFile,
    downloadFile,
    getFiles,
    getData,
    postData,
    sendEmail,
} from "../../../helpers/fetchHelpers";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import Dropzone from "../../energeticProtocolForm/inputs/Dropzone";
import Tooltip from "../../Tooltip";
import { AppContext, FormState } from "../../../App";
import Row from "../../Row";
import Col from "../../Col";
import FilesUploadModal from "../../energeticProtocolForm/FilesUploadModal";
import { config } from "../../../config";
import { calculatePeriodEnd, formatPeriodDate } from "../../../helpers/helpers";
import { clearCookie } from "../../../helpers/cookiesHelpers";
import Preloader from "../../Preloader";
import UnpaidOrderError from "./UnpaidOrderError";
import NonExistingOrderError from "./NonExistingOrderError";
import CompletedOrderMessage from "./CompletedOrderMessage";
import ConceptOrderError from "./ConceptOrderError";
import AlreadyCompletedOrderError from "./AlreadyCompletedOrderError";

interface Props {}

const FilesUploadPage: FC<Props> = ({ ...props }) => {
    const history = useHistory();
    const [showPreloader, setShowPreloader] = useState(true);
    const [completed, setCompleted] = useState(false);
    const [orderUuidIsValid, setOrderUuidIsValid] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [deletingFile, setDeletingFile] = useState(false);
    const { values, setValues, setValuesByKeys, clearValues } = useContext(
        AppContext
    );
    const params = useParams<{ uuid: string }>();
    const orderFiles = useRef(values.orderFiles);
    const valuesRef = useRef(values);
    const uuid = params.uuid;
    const data = useRef<FormState>({} as FormState);

    const periodEndDate = calculatePeriodEnd(values.period, values.periodStart);
    const periodEnd = formatPeriodDate(periodEndDate);
    const publicUrl = process.env.PUBLIC_URL + "/";

    useEffect(() => {
        uuid &&
            getData(uuid).then((res) => {
                if (res.status === "order_status_demand_created") {
                    history.push(`/order/${uuid}`);
                }
                data.current = res;
            });
    }, [history, uuid]);

    useEffect(() => {
        valuesRef.current = values;
    }, [values]);

    useEffect(() => {
        uuid &&
            getData(uuid)
                .then((data) => {
                    setOrderUuidIsValid(true);
                    setShowPreloader(false);
                    setValues({ ...valuesRef.current, ...data });
                })
                .catch((err) => {
                    setOrderUuidIsValid(false);
                    setShowPreloader(false);
                });
    }, [setValues, uuid]);

    useEffect(() => {
        orderFiles.current = values.orderFiles;
    }, [values.orderFiles]);

    useEffect(() => {
        if (uuid) {
            getFiles(uuid).then((orderFiles) => {
                setValuesByKeys(["orderFiles"], [orderFiles]);
            });
        }
    }, [params.uuid, setValuesByKeys, uuid]);

    const uploadFileFn = (f: { uuid: string; name: string; file: any }) => {
        return new Promise((resolve, reject) => {
            if (uuid) {
                uploadFile(uuid, f.file)
                    .then((fileId) => {
                        const otherFiles = orderFiles.current.filter(
                            (orderFile) => orderFile.uuid !== f.uuid
                        );
                        const file = {
                            uuid: fileId,
                            name: f.name,
                            loading: false,
                            deleting: false,
                            timestamp: Date.now(),
                        };
                        setValuesByKeys(
                            ["orderFiles"],
                            [[...otherFiles, file]]
                        );
                        resolve();
                    })
                    .catch((err) => {
                        const otherFiles = values.orderFiles.filter(
                            (orderFile) => orderFile.uuid !== f.uuid
                        );
                        setValuesByKeys(["orderFiles"], [otherFiles]);
                        toast("Súbor sa nepodarilo nahrať");
                        resolve();
                    });
            }
        });
    };

    const handleOnDrop = (acceptedFiles: any, rejectedFiles: any) => {
        if (rejectedFiles.length) {
            rejectedFiles.forEach((f: any) =>
                toast(`Typ súboru ${f.file.name} nie je podporovaný`)
            );
        }
        const filesToUpload = acceptedFiles.filter((file) => {
            if (file.size <= config.fileUpload.fileMaxSize) {
                return true;
            } else {
                toast(`Súbor ${file.name} je príliš veľký`);
                return false;
            }
        });

        const mappedFiles = filesToUpload.map((f: any) => {
            return {
                uuid: generateUuid(),
                name: f.name,
                file: f,
            };
        });
        setValuesByKeys(
            ["orderFiles"],
            [
                [
                    ...orderFiles.current,
                    ...mappedFiles.map((f) => {
                        return {
                            uuid: f.uuid,
                            name: f.name,
                            loading: true,
                            deleting: false,
                            timestamp: Date.now() + 3600000,
                        };
                    }),
                ],
            ]
        );
        mappedFiles.reduce((promise: Promise<any>, f) => {
            return promise.then(() => uploadFileFn(f));
        }, Promise.resolve());
    };

    const handleDeleteFile = (fileId: string) => {
        setDeletingFile(true);
        const otherFiles = values.orderFiles.filter((f) => f.uuid !== fileId);
        const file = values.orderFiles.find((f) => f.uuid === fileId);
        file &&
            setValuesByKeys(
                ["orderFiles"],
                [[...otherFiles, { ...file, deleting: true }]]
            );
        setTimeout(() => {
            deleteFile(fileId)
                .then(() => {
                    setValuesByKeys(["orderFiles"], [otherFiles]);
                    setDeletingFile(false);
                })
                .catch((err) => {
                    console.log(err);
                    setDeletingFile(false);
                });
        }, 1000);
    };

    const handleDownloadFile = (fileId: string, fileName: string) => {
        downloadFile(fileId, fileName).then((blob) => {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute("download", fileName);
            a.click();
        });
    };

    const handleSubmit = () => {
        if (values.orderFiles.length === 0) {
            toast("Nahrajte prosím faktúry za elektrinu");
        } else if (values.period && values.orderFiles.length < values.period) {
            setModalOpen(true);
        } else {
            complete();
        }
    };

    const sendEmailForFilesUpload = () => {
        uuid &&
            sendEmail(uuid, config.emailTypeCodes.files_upload_email)
                .then(() =>
                    toast(
                        "Odkaz pre neskoršie nahratie súborov bol odoslaný na Váš email."
                    )
                )
                .catch(() => toast("Pri odosielaní emailu nastala chyba."));
    };

    const clearValuesCookiesComplete = () => {
        clearCookie("uuid");
        clearValues();
        setCompleted(true);
    };

    const complete = () => {
        postData(uuid, data.current, 6)
            .then((res) => {
                if (res === "success") {
                    clearValuesCookiesComplete();
                } else {
                    throw new Error("Validation error");
                }
            })
            .catch((err) => toast("Objednávku sa nepodarilo dokončiť."));
    };

    const FilesUploadComponent = () => (
        <div className="order-form-wrapper files-upload">
            <h2>Podklady pre energetickú diagnostiku</h2>
            <h4>Číslo objednávky: {values.orderNumber}</h4>
            <Row>
                Zvolený počet mesiacov:
                <span style={{ marginLeft: "15px" }}>
                    <b>{values.period}</b>
                </span>
            </Row>
            <Row>
                Začiatok posudzovaného obdobia:
                <span style={{ marginLeft: "15px" }}>
                    <b>{`${values.periodStart.split("-")[1]}-${
                        values.periodStart.split("-")[0]
                    }`}</b>
                </span>
            </Row>
            <Row>
                Koniec posudzovaného obdobia:
                {periodEnd && (
                    <span style={{ marginLeft: "15px" }}>
                        <b>{periodEnd}</b>
                    </span>
                )}
            </Row>
            <Row>
                <span className="title">Nahratie súborov</span>
            </Row>
            <div>
                Pre spracovanie diagnostiky je potrebné priložiť súbory s
                faktúrami za elektrickú energiu za zvolený počet po sebe idúcich
                mesiacov. *
                <Tooltip>
                    <p>
                        Priložené môžu byť originálne pdf súbory faktúry, ktoré
                        ste obdržali emailom od energetickej spoločnosti, alebo
                        to môžu byť odfotené papierové faktúry (napr. mobilom),
                        príp. naskenované faktúry.
                    </p>
                    <p>
                        Nahrať môžete súbory v bežných formátoch (pdf, jpg, doc,
                        ...) a v rôznom poradí. Maximálna veľkosť jedného
                        priloženého súboru je 50 MB.
                    </p>
                </Tooltip>
                <p>
                    Ak potrebujete viac času na nahratie podkladových súborov,
                    môžete si na email zaslať odkaz pre ich neskoršie nahratie.
                </p>
            </div>
            <Row justify="flex-end">
                <button
                    className="button sendEmailButton"
                    onClick={() => sendEmailForFilesUpload()}
                >
                    Odoslať odkaz
                </button>
            </Row>
            <Dropzone
                files={values.orderFiles}
                deletingFile={deletingFile}
                onDrop={handleOnDrop}
                onDeleteFile={handleDeleteFile}
                onDownloadFile={handleDownloadFile}
            />
            <Row className="formButtonContainer">
                <Col>
                    <button className="button pink" onClick={handleSubmit}>
                        Odoslať súbory
                    </button>
                </Col>
            </Row>
        </div>
    );

    const FilesUploadPageContent = () => {
        const isConcept = values.status === "order_status_concept";
        const isUnpaid =
            values.status === "order_status_ordered" ||
            values.status === "order_status_payment_fail";
        const isAlreadyCompleted =
            values.status === "order_status_demand_created";
        if (!orderUuidIsValid) {
            return <NonExistingOrderError />;
        } else if (isUnpaid) {
            return <UnpaidOrderError />;
        } else if (isConcept) {
            return <ConceptOrderError />;
        } else if (isAlreadyCompleted) {
            return <AlreadyCompletedOrderError />;
        } else if (completed) {
            return <CompletedOrderMessage number={data.current.orderNumber} />;
        } else {
            return <FilesUploadComponent />;
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <Navbar showMenuItems={false} orderButton="hidden" />
            <div className="pd-top-120">
                <Row wrap="wrap">
                    <Col
                        className="order-image-bg wow animate fadeInLeft"
                        justify="flex-end"
                        align="flex-end"
                    >
                        <div className="order-image-wrapper">
                            <img
                                src={
                                    publicUrl +
                                    "assets/img/man-working-home.jpg"
                                }
                                alt="order"
                            />
                        </div>
                    </Col>
                    <Col className="orderForm wow animate fadeInRight">
                        {!showPreloader && <FilesUploadPageContent />}
                    </Col>
                </Row>
            </div>
            <FilesUploadModal
                open={modalOpen}
                closeHandler={() => setModalOpen(false)}
                cancelBtnHandler={() => setModalOpen(false)}
                submitBtnHandler={() => {
                    setModalOpen(false);
                    complete();
                }}
            />
            <Footer />
        </>
    );
};

export default FilesUploadPage;
