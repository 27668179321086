import React, { useContext } from "react";

import EnergeticProtocolForm from "../../../energeticProtocolForm/EnergeticProtocolForm";
import Row from "../../../Row";
import Col from "../../../Col";
import Tooltip from "../../../Tooltip";
import { AppContext } from "../../../../App";

const Order = () => {
    const { step } = useContext(AppContext);
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div id="order" className="map-area pd-top-120 orderSection">
            <div className="formBox homepage">
                <Row justify="center" wrap="wrap">
                    <Col
                        className="order-image-bg wow animate fadeInLeft"
                        justify="flex-end"
                        align="flex-end"
                    >
                        <div className="order-image-wrapper">
                            <img
                                src={
                                    publicUrl +
                                    "assets/img/man-working-home.jpg"
                                }
                                alt="order"
                            />
                        </div>
                    </Col>
                    <Col className="orderForm homepage wow animate fadeInRight">
                        <div className="order-form-wrapper">
                            {step === 1 && (
                                <>
                                    <div style={{ marginBottom: "50px" }}>
                                        <div className="orderTitle">
                                            Cena služby:
                                        </div>
                                        <div className="orderPrice">
                                            4,90&nbsp;€&nbsp;/ analyzovaná
                                            faktúra
                                            <Tooltip iconFontSize="16px">
                                                Zľavové kupóny sa dajú uplatniť
                                                v 2. kroku formulára
                                            </Tooltip>
                                            <div className="priceText">
                                                Ceny sú udávané bez DPH
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div>
                                <span className="orderTitle">Objednávka:</span>
                                <EnergeticProtocolForm />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Order;
