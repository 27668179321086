import React, { FC, RefObject } from "react";
import cn from "classnames";
import ReactDropzone, { DropzoneRef } from "react-dropzone";

import Icon from "../../Icon";
import Col from "../../Col";
import Row from "../../Row";
import { FileInfo } from "../../../App";

interface Props {
    files: FileInfo[];
    deletingFile?: boolean;
    onDrop: (acceptedFiles: any, rejectedFiles: any) => void;
    onDeleteFile: (id: string) => void;
    onDownloadFile: (fileId: string, fileName: string) => void;
    inputRef?: RefObject<DropzoneRef>; //React.MutableRefObject<HTMLInputElement>;
}

const Dropzone: FC<Props> = ({
    files,
    deletingFile = false,
    onDrop,
    onDeleteFile,
    onDownloadFile,
    inputRef,
    ...props
}) => {
    return (
        <div className="dropzoneContainer">
            <ReactDropzone
                onDrop={onDrop}
                multiple={true}
                accept="image/*, .pdf, .doc, .docx, .zip, .rar, .7z"
                ref={inputRef}
            >
                {({ getRootProps, getInputProps }) => (
                    <section style={{ height: "100%" }}>
                        <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p>
                                Sem presuňte Vaše súbory s faktúrami alebo
                                kliknite&nbsp;a&nbsp;vyberte súbory v dialógovom
                                okne
                            </p>
                        </div>
                    </section>
                )}
            </ReactDropzone>
            <Col>
                {files.length !== 0 && (
                    <Row className="filesHead">
                        <Col width={8}>Názov nahratého súboru</Col>
                        <Col width={1}></Col>
                    </Row>
                )}
                <Col className="filesBody">
                    {files
                        .sort((a, b) => (a.timestamp >= b.timestamp ? 1 : -1))
                        .map((f, key: number) => (
                            <React.Fragment key={key}>
                                <Row>
                                    <Col className="fileNameCol">
                                        <div
                                            className="downloadFileLink"
                                            onClick={(e) =>
                                                onDownloadFile(f.uuid, f.name)
                                            }
                                        >
                                            {f.name}
                                        </div>
                                    </Col>
                                    <Col
                                        className="removeBtnCol"
                                        align="flex-end"
                                    >
                                        {f.loading ? (
                                            <div className="loader"></div>
                                        ) : f.deleting ? (
                                            <div className="loader pink"></div>
                                        ) : (
                                            <Icon
                                                name="cross"
                                                className={cn(
                                                    "fileRemoveIcon",
                                                    deletingFile
                                                        ? "disabled"
                                                        : ""
                                                )}
                                                onClick={(e) =>
                                                    !deletingFile &&
                                                    onDeleteFile(f.uuid)
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ))}
                </Col>
            </Col>
        </div>
    );
};

export default Dropzone;
