import React, { useState, useCallback, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { readCookie } from "./helpers/cookiesHelpers";
import VOP from "./components/pages/VOP";
import GDPR from "./components/pages/GDPR";
import PostPaymentPage from "./components/pages/PostPaymentPage/PostPaymentPage";
import HomePage from "./components/pages/HomePage/HomePage";
import OrderPage from "./components/pages/OrderPage";
import FilesUploadPage from "./components/pages/FilesUploadPage/FilesUploadPage";
import CookiesPanel from "./components/CookiesPanel";

export interface FileInfo {
    uuid: string;
    name: string;
    loading?: boolean;
    deleting?: boolean;
    timestamp: number;
}

export interface FormState {
    forCompanyConfirm: boolean;
    fullname: string;
    email: string;
    phone: string;
    gdprAgree: boolean;
    companyName: string;
    companyEic: string;
    companyIco: string;
    dic: string;
    icdph: string;
    street: string;
    city: string;
    postalCode: string;
    businessConditionsAgreement: boolean;
    orderFiles: FileInfo[];
    orderNumber: string;
    period: number | null;
    periodStart: string;
    coupon: string | null;
    couponRedeemed: boolean;
    price: number | null;
    calculatedDiscount: number | null;
    discountRate: number | null;
    discountedPrice: number | null;
    discountedVatPrice: number | null;
    paymentMethod: string | null;
    status: string | null;
    payedAt: string | null;
    createdAt: string | null;
    createCompletedAt: string | null;
}

const initFormState = {
    forCompanyConfirm: false,
    fullname: "",
    email: "",
    phone: "",
    gdprAgree: false,
    companyName: "",
    companyEic: "",
    companyIco: "",
    dic: "",
    icdph: "",
    street: "",
    city: "",
    postalCode: "",
    businessConditionsAgreement: false,
    orderFiles: [] as FileInfo[],
    orderNumber: "",
    period: null,
    periodStart: "",
    coupon: null,
    couponRedeemed: false,
    price: null,
    calculatedDiscount: null,
    discountRate: null,
    discountedPrice: null,
    discountedVatPrice: null,
    paymentMethod: null,
    status: null,
    payedAt: null,
    createdAt: null,
    createCompletedAt: null,
};

interface AppContextType {
    uuid: string | undefined;
    setUuid: (uuid: string | undefined) => void;
    values: FormState;
    setValuesByKeys: (keys: string[], vals: any[]) => void;
    setValues: (values: FormState) => void;
    clearValues: () => void;
    errors: { [key: string]: string[] };
    setErrors: (errors: { [key: string]: string[] }) => void;
    setErrorByKey: (key: string, value: any) => void;
    cookiesAgreement: boolean;
    step: number;
    setStep: (step: number) => void;
}

export const AppContext = React.createContext<AppContextType>({
    uuid: undefined,
    setUuid: (uuid: string | undefined) => {},
    values: initFormState,
    setValuesByKeys: (keys: string[], vals: any[]) => {},
    setValues: (values: FormState) => {},
    clearValues: () => {},
    errors: {},
    setErrorByKey: (key: string, value: any) => {},
    setErrors: (errors: { [key: string]: string[] }) => {},
    cookiesAgreement: false,
    step: 1,
    setStep: (step: number) => {},
});

function App() {
    const [uuid, setUuid] = useState<string | undefined>(undefined);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const [values, setValues] = useState<FormState>(initFormState);
    const valuesRef = useRef<FormState>(values);
    const [step, setStep] = useState(1);
    const [cookiesAgreement, setCookiesAgreement] = useState(false);

    useEffect(() => {
        setCookiesAgreement(readCookie("cookiesAgreement") === "true");
    }, []);

    useEffect(() => {
        valuesRef.current = values;
    }, [values]);

    const clearValues = () => {
        setValues(initFormState);
    };

    const setValuesByKeys = useCallback(
        (keys: string[], vals: any[]) => {
            const partialState = keys.reduce(
                (obj: { [key: string]: any }, key: string, idx: number) => {
                    obj[key] = vals[idx];
                    return obj;
                },
                {}
            );
            return setValues({ ...valuesRef.current, ...partialState });
        },
        [valuesRef]
    );

    const setErrorByKey = useCallback(
        (key: string, value: any) => {
            return setErrors({ ...errors, [key]: value });
        },
        [errors]
    );

    return (
        <AppContext.Provider
            value={{
                uuid,
                setUuid,
                values,
                setValuesByKeys,
                setValues,
                clearValues,
                errors,
                setErrors,
                setErrorByKey,
                cookiesAgreement,
                step,
                setStep,
            }}
        >
            <Router>
                <Switch>
                    <Route path="/post_payment">
                        <PostPaymentPage />
                    </Route>
                    <Route path="/vop" component={VOP} />
                    <Route path="/gdpr" component={GDPR} />
                    <Route
                        path="/files_upload/:uuid"
                        component={FilesUploadPage}
                    />
                    <Route path="/order/:uuid?">
                        <OrderPage />
                        {!cookiesAgreement && (
                            <CookiesPanel
                                setCookiesAgreement={setCookiesAgreement}
                            />
                        )}
                    </Route>
                    <Route path="/">
                        <HomePage />
                        {!cookiesAgreement && (
                            <CookiesPanel
                                setCookiesAgreement={setCookiesAgreement}
                            />
                        )}
                    </Route>
                </Switch>
            </Router>
            <ToastContainer
                toastClassName="toast"
                bodyClassName="toastBody"
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                rtl={false}
                pauseOnHover
                closeOnClick={false}
                transition={cssTransition({
                    enter: "toastEnter",
                    exit: "toastExit",
                })}
            />
        </AppContext.Provider>
    );
}

export default App;
