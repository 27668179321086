import React from "react";
import Navbar from "../../Navbar";
import Protocol from "./sections/Protocol";
import Findout from "./sections/Findout";
import WhatWeDo from "./sections/WhatWeDo";
import Diagnostics from "./sections/Diagnostics";
import HowItWorks from "./sections/HowItWorks";
import Footer from "../../Footer";
import Order from "./sections/Order";
import Header from "./sections/Header";
import HowItWorksVideo from "./sections/HowItWorksVideo";

const HomePage = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <HowItWorksVideo />
            <Findout />
            <Protocol />
            <WhatWeDo />
            <Diagnostics />
            <HowItWorks />
            <Order />
            <Footer homepage={true} />
        </div>
    );
};

export default HomePage;
