import React, { useEffect, useCallback, useContext, useRef } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { v4 as generateUuid } from "uuid";

import { AppContext, FormState } from "../../App";
import { readCookie } from "../../helpers/cookiesHelpers";
import { getData } from "../../helpers/fetchHelpers";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";

const EnergeticProtocolForm = () => {
    const { uuid, setUuid, values, setValues, step, setStep } = useContext(
        AppContext
    );
    const valuesRef = useRef<FormState>(values);
    const params = useParams<{ uuid: string }>();
    const history = useHistory();
    const { url } = useRouteMatch();

    useEffect(() => {
        valuesRef.current = values;
    }, [values]);

    useEffect(() => {
        let id = params.uuid || readCookie("uuid");
        if (id) {
            getData(id).then((data) => {
                setValues({ ...valuesRef.current, ...data });
            });
        } else {
            id = generateUuid();
        }
        setUuid(id);
    }, [params.uuid, setValues, setUuid]);

    const paymentMountHandler = useCallback(() => {
        uuid &&
            getData(uuid).then((data) => {
                setValues({ ...valuesRef.current, ...data });
            });
    }, [uuid, setValues]);

    return (
        <>
            {step === 1 && (
                <FormStep1
                    onSubmit={() => {
                        setStep(2);
                        if (!url.startsWith("/order")) {
                            history.push("/order");
                        }
                    }}
                />
            )}
            {step === 2 && (
                <FormStep2
                    onBack={() => setStep(1)}
                    onSubmit={() => setStep(3)}
                />
            )}
            {step === 3 && (
                <FormStep3
                    onMount={paymentMountHandler}
                    onBack={() => setStep(2)}
                />
            )}
        </>
    );
};

export default EnergeticProtocolForm;
