import React, { FC, useEffect, useState } from "react";
import cn from "classnames";

import Row from "./Row";
import Icon from "./Icon";
import Col from "./Col";
import { Link } from "react-router-dom";
import ContactModal from "./ContactModal";

interface Props {
  homepage?: boolean;
}

const Footer: FC<Props> = ({ homepage = false, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const publicUrl = process.env.PUBLIC_URL;

  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";
    document.body.appendChild(minscript);
  }, []);

  return (
    <>
      <footer
        className={cn("footer-area pt-5", !homepage ? "mg-top-120" : "light")}
      >
        <div className="container footerContainer">
          <Row className="footer" justify="space-around" wrap="wrap">
            <Col className="helpWith">
              <Col className="footerColContainer helpWithCol">
                <h2>Sme Váš partner v energetike</h2>
                <Row className="helpWithRow" wrap="wrap">
                  <Col>
                    <span>Zabezpečíme Vám aj:</span>
                    <div className="helpWithItem">Zníženie cien energií</div>
                    <div className="helpWithItem">LED osvetlenie</div>
                    <div className="helpWithItem">Fotovoltaické elektrárne</div>
                    <div className="helpWithItem">Tepelné hospodárstvo</div>
                  </Col>
                  <Col>
                    <span>Pomôžeme s financovaním:</span>
                    <div className="helpWithItem">EÚ fondy</div>
                    <div className="helpWithItem">EPC projekty</div>
                    <div className="helpWithItem">Splátkové financovanie</div>
                  </Col>
                </Row>
                <Row>
                  <button
                    className="button pink footerBtn"
                    onClick={() => setModalOpen(true)}
                  >
                    Mám záujem
                  </button>
                </Row>
              </Col>
            </Col>
            <Col className="info">
              <div className="footerColContainer">
                <h4>Naše údaje:</h4>
                <ul>
                  <li>Energo&nbsp;audit&nbsp;s.r.o.</li>
                  <li>Levická 7D</li>
                  <li>949&nbsp;01 Nitra</li>
                  <br />
                  <li>IČO: 50&nbsp;341&nbsp;120</li>
                  <li>DIČ: 2120&nbsp;290&nbsp;579</li>
                  <li>IČ DPH: SK21&nbsp;20&nbsp;290&nbsp;579</li>
                  <li>
                    <Link to={`/vop`} target="_blank">
                      Všeobecné obchodné podmienky
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="contactInfo">
              <div className="footerColContainer">
                <h4>Kontakt:</h4>
                <Row align="center">
                  <Icon name="mail" size="25px" className="mailIcon" />
                  <span
                    style={{
                      fontSize: "22px",
                      marginLeft: "10px",
                    }}
                  >
                    helpdesk@endia.sk
                  </span>
                </Row>
                <hr />
                <Row gap="20px" wrap="wrap">
                  <Col className="endiaLogoCol" align="center">
                    <Row justify="center">
                      <img
                        src={`${publicUrl}/assets/img/logos/energoaudit.png`}
                        alt="logo-endia"
                        width="80"
                        height="80"
                      />
                    </Row>
                    <Row className="eaText" justify="center" align="center">
                      <a
                        className="eaLink"
                        href="https://energoaudit.sk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.energoaudit.sk
                      </a>
                    </Row>
                  </Col>
                  <Col className="apesLogoCol" align="center">
                    <Row justify="center">
                      <img
                        src={`${publicUrl}/assets/img/logos/APES.png`}
                        alt="logo-apes"
                        width="200"
                        height="80"
                      />
                    </Row>
                    <Row className="apesText" justify="center" align="center">
                      člen asociácie
                      <br />
                      poskytovateľov
                      <br />
                      energetických služieb
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
      <ContactModal open={modalOpen} closeHandler={() => setModalOpen(false)} />
    </>
  );
};

export default Footer;
