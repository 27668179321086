import React, { FC, useEffect } from "react";

import Col from "../Col";
import Row from "../Row";
import { FormState } from "../../App";
import {
    calculatePeriodEnd,
    formatPeriodDate,
    formatPayedDate,
} from "../../helpers/helpers";
import { downloadProtocol } from "../../helpers/fetchHelpers";

interface Props {
    uuid: string;
    data: FormState;
}

const ReadOnlyOrderPage: FC<Props> = ({ uuid, data, ...props }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function formatNumber(val: number | null) {
        return val
            ? val
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  .replace(".", ",")
            : "";
    }

    const periodEndDate = calculatePeriodEnd(data.period, data.periodStart);
    const periodEnd = formatPeriodDate(periodEndDate);
    const periodStart =
        data &&
        data.periodStart &&
        `${data.periodStart.split("-")[1]}-${data.periodStart.split("-")[0]}`;

    const createCompletedAt =
        data.createCompletedAt && formatPayedDate(data.createCompletedAt);

    const handleDownloadProtocol = () => {
        downloadProtocol(uuid).then((blob) => {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute("download", "protocol");
            a.click();
        });
    };

    return (
        <>
            <div className="formStep3Container">
                <Row>
                    <Col width={2}>Názov spoločnosti:</Col>
                    <Col width={3}>
                        <b>{data.companyName}</b>
                    </Col>
                </Row>
                <div className="paymentSection">Kontaktná osoba:</div>
                <Row>
                    <Col width={2}>Meno a priezvisko:</Col>
                    <Col width={3}>
                        <b>{data.fullname}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Emailová adresa:</Col>
                    <Col width={3}>
                        <b>{data.email}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Telefónne číslo:</Col>
                    <Col width={3}>
                        <b>{data.phone}</b>
                    </Col>
                </Row>
                <div className="paymentSection">Údaje o spoločnosti:</div>
                <Row>
                    <Col width={2}>IČO spoločnosti:</Col>
                    <Col width={3}>
                        <b>{data.companyIco}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>DIČ:</Col>
                    <Col width={3}>
                        <b>{data.dic}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>IČ DPH:</Col>
                    <Col width={3}>
                        <b>{data.icdph}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Adresa - Ulica, číslo:</Col>
                    <Col width={3}>
                        <b>{data.street}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Obec:</Col>
                    <Col width={3}>
                        <b>{data.city}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>PSČ:</Col>
                    <Col width={3}>
                        <b>{data.postalCode}</b>
                    </Col>
                </Row>
                <div className="paymentSection">Údaje o objednávke:</div>
                <Row>
                    <Col width={2}>Číslo objednávky:</Col>
                    <Col width={3}>
                        <b>{data.orderNumber}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Predmet:</Col>
                    <Col width={3}>
                        <b>
                            Diagnostika účtovaných položiek za elektrinu -
                            vypracovanie protokolu energetickej diagnostiky
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>EIC:</Col>
                    <Col width={3}>
                        <b>{data.companyEic}</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Posudzované obdobie:</Col>
                    <Col width={3}>
                        <b>
                            od {periodStart} do {periodEnd} ({data.period}
                            &nbsp;mesiacov)
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Základná cena bez DPH:</Col>
                    <Col width={3}>
                        <b>{formatNumber(data.price)} Eur</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Poskytnutá zľava:</Col>
                    <Col width={3}>
                        <b>
                            {data.calculatedDiscount
                                ? "-" +
                                  formatNumber(data.calculatedDiscount) +
                                  " "
                                : "0,00 "}
                            Eur
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Výsledná cena bez DPH:</Col>
                    <Col width={3}>
                        <b>{formatNumber(data.discountedPrice)} Eur</b>
                    </Col>
                </Row>
                <Row>
                    <Col width={2}>Výsledná cena s DPH:</Col>
                    <Col width={3}>
                        <b>{formatNumber(data.discountedVatPrice)} Eur</b>
                    </Col>
                </Row>
                <Row align="center">
                    <Col width={2}>Spôsob platby:</Col>
                    <Col width={3}>
                        <b>Platobná karta</b>
                    </Col>
                </Row>
                <Row align="center">
                    <Col width={2}>Dátum vytvorenia objednávky:</Col>
                    <Col width={3}>
                        <b>{createCompletedAt}</b>
                    </Col>
                </Row>
                {data.status !== "order_status_demand_done" && (
                    <Row>
                        <Col width={2}>Dodanie protokolu:</Col>
                        <Col width={3}>
                            <b>
                                Do e-mailovej schránky objednávateľa, formát pdf
                            </b>
                        </Col>
                    </Row>
                )}
                {data.status === "order_status_demand_done" && (
                    <Row className="formButtonContainer">
                        <Col>
                            <button
                                className="button"
                                onClick={handleDownloadProtocol}
                            >
                                Stiahnuť protokol
                            </button>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
};

export default ReadOnlyOrderPage;
