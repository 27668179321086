import React, { useEffect, useRef } from "react";

import Row from "../../Row";
import Col from "../../Col";
import { useHistory, useLocation } from "react-router-dom";
import { sendEmail, postData, getData } from "../../../helpers/fetchHelpers";
import { config } from "../../../config";
import { toast } from "react-toastify";
import { FormState } from "../../../App";

const PaymentSuccess = () => {
    const history = useHistory();
    const uuid = new URLSearchParams(useLocation().search).get("order_uuid");
    const orderNumber = new URLSearchParams(useLocation().search).get("number");
    const data = useRef<FormState | null>(null);
    const publicUrl = process.env.PUBLIC_URL;

    useEffect(() => {
        uuid &&
            getData(uuid).then((res) => {
                data.current = res;
            });
    }, [uuid]);

    const handleSubmit = () => {
        uuid && data.current && postData(uuid, data.current, 5).then();
        history.push(`/files_upload/${uuid}`);
    };

    const sendEmailForFilesUpload = () => {
        uuid &&
            sendEmail(uuid, config.emailTypeCodes.files_upload_email)
                .then(() =>
                    toast(
                        "Odkaz pre neskoršie nahratie súborov bol odoslaný na Váš email."
                    )
                )
                .catch(() => toast("Pri odosielaní emailu nastala chyba."));
    };

    return (
        <div className="messageBox">
            <h2>Ďakujeme!</h2>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/success.png`}
                    alt="success"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Vaša objednávka č. {orderNumber} bola úspešne zaevidovaná.
                </div>
            </Row>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/success.png`}
                    alt="success"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Vaša platba bola úspešne zrealizovaná.
                </div>
            </Row>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/warning.png`}
                    alt="attention"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Nahrajte Vaše faktúry na spracovanie.
                </div>
            </Row>
            <div className="messageBoxInfo">
                <p>
                    Dokončite prosím proces vytvárania objednávky nahratím
                    potrebných podkladov. Ak na prípravu podkladov potrebujete
                    viac času, do formuláru pre nahratie súborov sa môžete
                    neskôr vrátiť na tejto adrese:
                    <br />
                    <a href={`${window.location.origin}/files_upload/${uuid}`}>
                        {window.location.origin}/files_upload/{uuid}
                    </a>
                </p>
                <Row justify="flex-end">
                    <button
                        className="button sendEmailButton"
                        onClick={() => sendEmailForFilesUpload()}
                    >
                        Odoslať odkaz
                    </button>
                </Row>
            </div>
            <Row className="formButtonContainer">
                <Col width={1}>
                    <button
                        className="button pink"
                        onClick={() => handleSubmit()}
                    >
                        Prejsť na nahrávanie faktúr
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentSuccess;
