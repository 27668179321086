import React from "react";

import Col from "../../../Col";
import Icon from "../../../Icon";
import Row from "../../../Row";

const WhatWeDo = () => {
    const publicUrl = process.env.PUBLIC_URL;
    const checkIcon = (
        <img
            src={`${publicUrl}/assets/img/icons/check.png`}
            alt="check"
            width="18"
            height="18"
        />
    );

    return (
        <div className="whatWeDoSection" id="whatWeDo">
            <div className="sbs-provide-security pd-top-120">
                <div className="container">
                    <div
                        className="wow animated fadeInRight"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                    >
                        <div className="section-title style-two">
                            <h2 className="title">
                                V energetickej diagnostike vyhodnocujeme
                            </h2>
                        </div>
                    </div>
                    <div className="riyaqas-stab">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#tab1"
                                >
                                    Kritériá
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tab2"
                                >
                                    Upozornenia
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tab3"
                                >
                                    Vyhodnotenie
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div
                                className="tab-pane fade active show"
                                id="tab1"
                            >
                                <div className="riyaqas-stab-content">
                                    <Row wrap="wrap">
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>MRK a RK</h4>
                                            Analyzujeme priebeh výkonu vo vzťahu
                                            k nastaveným hodnotám MRK a RK.
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    {checkIcon}
                                                </Col>
                                                <Col>
                                                    Po analýze Vám poskytneme
                                                    odporúčané nastavenie a
                                                    informáciu ako to urobiť.
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Jalový odber</h4>
                                            Skontrolujeme, či Vám distribúcia
                                            neúčtuje prirážky za nedodržanie
                                            účinníka.
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    {checkIcon}
                                                </Col>
                                                <Col>
                                                    Ak prirážky sú účtované,
                                                    vypočítame návratnosť
                                                    investície za kompenzačné
                                                    zariadenie.
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Jalová dodávka</h4>
                                            Preveríme, či na odbernom mieste
                                            nemáte jalovú dodávku a v akej výške
                                            za ňu účtuje distribúcia prirážky.
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    {checkIcon}
                                                </Col>
                                                <Col>
                                                    Ak prirážky sú účtované,
                                                    vypočítame návratnosť
                                                    investície za kompenzačné
                                                    zariadenie.
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Priebeh spotreby</h4>
                                            Pozrieme sa na nastavenie vysokého a
                                            nízkeho tarifu a ich správne
                                            nastavenie.
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    {checkIcon}
                                                </Col>
                                                <Col>
                                                    Dostanete od nás informáciu,
                                                    či sú tarify správne
                                                    nastavené.
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Fakturačné nezrovnalosti</h4>
                                            Prekontrolujeme aj správnosť
                                            fakturácie.
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    {checkIcon}
                                                </Col>
                                                <Col>
                                                    Prípadnú nezrovnalosť
                                                    uvedieme do analýzy.
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2">
                                <div className="riyaqas-stab-content">
                                    <Row wrap="wrap">
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>MRK a RK</h4>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="arrow-up"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>Privysoká kapacita</Col>
                                            </Row>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="arrow-down"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Nedostačujúca kapacita
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Jalový odber</h4>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="exclamation"
                                                        color="orange"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sú účtované prirážky v
                                                    tolerancii
                                                </Col>
                                            </Row>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="close"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sú účtované prirážky treba
                                                    to riešiť
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Jalová dodávka</h4>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="exclamation"
                                                        color="orange"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sú účtované prirážky v
                                                    tolerancii
                                                </Col>
                                            </Row>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="close"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sú účtované prirážky treba
                                                    to riešiť
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Vysoká a nízka tarifa</h4>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="close"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Tarify sú účtované zle
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Fakturačné nezrovnalosti</h4>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="close"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sadzby sú účtované nesprávne
                                                </Col>
                                            </Row>
                                            <Row
                                                className="wwd-point"
                                                align="baseline"
                                            >
                                                <Col className="wwd-point-icon">
                                                    <Icon
                                                        name="close"
                                                        color="red"
                                                    />
                                                </Col>
                                                <Col>
                                                    Sú fakturované položky
                                                    navyše
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab3">
                                <div className="riyaqas-stab-content">
                                    <Row wrap="wrap">
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Kondícia odberného miesta</h4>
                                            Spracujeme celkové hodnotenie
                                            odberného miesta.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Možná úspora</h4>
                                            Vypočítame Vám možnú úsporu, ktorú
                                            môžete dosiahnuť.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Stavy sú sledované kritéria</h4>
                                            Vyhodnotíme stav každého sledovaného
                                            kritéria.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Navrhované riešenia</h4>
                                            Podrobne rozpíšeme navrhované
                                            riešenia, ktoré by bolo vhodné
                                            urobiť na odbernom mieste.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Postupy</h4>
                                            Ku každému navrhovanému riešeniu
                                            uvedieme postup realizácie.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Grafy sledovaných kritérií</h4>
                                            Priebeh sledovaných kritérií
                                            vykreslíme v grafoch, v ktorých
                                            uvidíte, kde vznikli nežiadúce
                                            stavy.
                                        </div>
                                        <div
                                            className="wwd-box wow animated fadeInUp"
                                            data-wow-duration="0.4s"
                                            data-wow-delay="0.1s"
                                        >
                                            <h4>Tabuľka zdrojových dát</h4>
                                            Dostanete k dispozícii sumarizačnú
                                            tabuľku všetkých dát, ktoré sme
                                            použili pri vyhodnotení.
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDo;
