import React, { FC, useContext, useEffect, useState } from "react";
// import DocumentMeta from "react-document-meta";

import Navbar from "../Navbar";
import Footer from "../Footer";
import Row from "../Row";
import Col from "../Col";
import Tooltip from "../Tooltip";
import EnergeticProtocolForm from "../energeticProtocolForm/EnergeticProtocolForm";
import { AppContext, FormState } from "../../App";
import ReadOnlyOrderPage from "./ReadOnlyOrderPage";
import { useHistory, useParams } from "react-router-dom";
import { getData } from "../../helpers/fetchHelpers";
import { readCookie } from "../../helpers/cookiesHelpers";
import Preloader from "../Preloader";

const OrderPage: FC = () => {
    const { step, values, setValues } = useContext(AppContext);
    const params = useParams<{ uuid: string }>();
    const history = useHistory();
    const [isConcept, setIsConcept] = useState(
        !values.status || values.status === "order_status_concept"
            ? true
            : false
    );
    const [data, setData] = useState<FormState>({} as FormState);
    const [loading, setLoading] = useState<boolean>(true);

    const uuidParam = params.uuid || readCookie("uuid");
    let publicUrl = process.env.PUBLIC_URL + "/";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (uuidParam) {
            setLoading(true);
            getData(uuidParam).then((res) => {
                if (
                    res.status &&
                    (res.status === "order_status_ordered" ||
                        res.status === "order_status_payment_fail" ||
                        res.status === "order_status_payment_success")
                ) {
                    history.push(
                        `/post_payment/?payment_code=${
                            res.status === "order_status_payment_success"
                                ? 0
                                : 1
                        }&order_uuid=${uuidParam}&number=${
                            res.orderNumber
                        }&description=`
                    );
                } else {
                    setData(res);
                    setIsConcept(
                        res.status && res.status === "order_status_concept"
                    );
                    if (res.status && res.status === "order_status_concept") {
                        setValues(res);
                    }
                }
                setLoading(false);
            });
        }
    }, [history, setValues, uuidParam]);

    return (
        // <DocumentMeta
        //     {...{
        //         title:
        //             "Energetická diagnostika - Znížte si faktúry za elektriku",
        //     }}
        // >
        <>
            <Preloader show={loading} />
            <Navbar showMenuItems={false} orderButton="disabled" />
            <div id="order" className="pd-top-120 orderSection">
                <div className="formBox">
                    <Row wrap="wrap">
                        <Col
                            className="order-image-bg wow animate fadeInLeft"
                            justify="flex-end"
                            align="flex-end"
                        >
                            <div className="order-image-wrapper">
                                <img
                                    src={
                                        publicUrl +
                                        "assets/img/man-working-home.jpg"
                                    }
                                    alt="order"
                                />
                            </div>
                        </Col>
                        <Col className="orderForm wow animate fadeInRight">
                            <div className="order-form-wrapper">
                                <Row
                                    className="orderTitleRow"
                                    justify="space-between"
                                    wrap="wrap"
                                >
                                    <div>
                                        <span className="orderTitle">
                                            Objednávka
                                        </span>
                                        {(!uuidParam || isConcept) && (
                                            <Tooltip
                                                classNames="orderTooltipIcon"
                                                iconFontSize="16px"
                                            >
                                                <div className="orderTooltipTitle">
                                                    Postup objednávky a
                                                    realizácie
                                                </div>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Krok 1 - Kontaktné údaje
                                                    </span>
                                                    Zadanie základných údajov
                                                    pre možnosť komunikácie.
                                                </p>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Krok 2 - Detaily
                                                        objednávky
                                                    </span>
                                                    Zadanie údajov o požadovanom
                                                    období energetickej
                                                    diagnostiky, údaje
                                                    spoločnosti, odbernom mieste
                                                    a vyčíslenie ceny
                                                    diagnostiky.
                                                </p>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Krok 3 - Sumarizácia
                                                        objednávky
                                                    </span>
                                                    Sumarizácia všetkých údajov
                                                    objednávky, spôsob platby a
                                                    dokončenie objednávky.
                                                </p>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Platba
                                                    </span>
                                                    Online platba
                                                    prostredníctvom platobnej
                                                    brány.
                                                </p>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Nahratie podkladov pre
                                                        analýzu
                                                    </span>
                                                    Nahratie faktúr za el.
                                                    energiu, ktoré náš energetik
                                                    bude analyzovať.
                                                </p>
                                                <p>
                                                    <span className="orderTooltipStep">
                                                        Výstupný protokol
                                                        analýzy
                                                    </span>
                                                    Do 5 pracovných dní Vám bude
                                                    na e-mail doručený pdf
                                                    protokol s výstupom analýzy
                                                    a optimalizačnými
                                                    odporúčaniami.
                                                </p>
                                            </Tooltip>
                                        )}
                                    </div>
                                    {step === 1 && isConcept && (
                                        <div className="orderPrice">
                                            4,90&nbsp;€&nbsp;/ analyzovaná
                                            faktúra
                                            <Tooltip iconFontSize="16px">
                                                Zľavové kupóny sa dajú uplatniť
                                                v 2. kroku formulára
                                            </Tooltip>
                                            <div className="priceText">
                                                Ceny sú udávané bez DPH
                                            </div>
                                        </div>
                                        //     <Col className="orderPriceWrapper">
                                        //     <div className="orderPrice">
                                        //         <div className="priceUnit">
                                        //             Eur
                                        //         </div>
                                        //         <div className="priceBig">
                                        //             4
                                        //         </div>
                                        //         <div className="priceSmall">
                                        //             .90 /
                                        //         </div>
                                        //         <div className="pricePerUnit">
                                        //             analyzovaná faktúra{" "}
                                        //             <Tooltip>
                                        //                 Zľavové kupóny sa dajú
                                        //                 uplatniť v 2. kroku
                                        //                 formulára
                                        //             </Tooltip>
                                        //         </div>
                                        //         <div className="priceText">
                                        //             Cena je udávaná bez DPH
                                        //         </div>
                                        //     </div>
                                        // </Col>
                                    )}
                                </Row>
                                {!uuidParam || isConcept ? (
                                    <EnergeticProtocolForm />
                                ) : (
                                    <ReadOnlyOrderPage
                                        uuid={uuidParam}
                                        data={data}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
            {/* </DocumentMeta> */}
        </>
    );
};

export default OrderPage;
