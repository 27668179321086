import React, { FC, useEffect, useState } from "react";
import cn from "classnames";

import navbarData from "./data/navbar.json";
import Icon from "./Icon";

interface Props {
    showMenuItems?: boolean;
    orderButton?: "hidden" | "disabled" | "enabled";
    closeButton?: boolean;
}

const Navbar: FC<Props> = ({
    showMenuItems = true,
    orderButton = "enabled",
    closeButton = false,
    ...props
}) => {
    const [scrolled, setScrolled] = useState(false);
    const publicUrl = process.env.PUBLIC_URL + "/";

    function setFixedPosition() {
        if (window.pageYOffset === 0) {
            setScrolled(false);
        } else {
            setScrolled(true);
        }
    }

    useEffect(() => {
        setFixedPosition();
        const onScroll = (e) => {
            setFixedPosition();
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <div>
            <nav
                className={cn(
                    "navbar navbar-area navbar-expand-lg nav-style-01",
                    scrolled ? "navbar-area-fixed" : "navbar-on-top"
                )}
            >
                <div className="container nav-container">
                    <div className="responsive-mobile-menu">
                        <div className="logo-wrapper mobile-logo">
                            <a href="/" className="logo">
                                <img
                                    src={
                                        publicUrl + "assets/img/logos/endia.png"
                                    }
                                    alt="logo"
                                    width="50"
                                    height="50"
                                />
                            </a>
                        </div>
                        {showMenuItems && (
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#Riyaqas_main_menu"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </button>
                        )}
                    </div>
                    <div
                        className="collapse navbar-collapse"
                        id="Riyaqas_main_menu"
                    >
                        <div className="logo-wrapper desktop-logo">
                            <a href="/" className="logo">
                                <img
                                    src={
                                        publicUrl + "assets/img/logos/endia.png"
                                    }
                                    alt="logo"
                                    width="50"
                                    height="50"
                                />
                            </a>
                        </div>
                        {showMenuItems && (
                            <ul
                                className={cn(
                                    "navbar-nav",
                                    !scrolled ? "navbar-on-top-nav" : ""
                                )}
                            >
                                {navbarData.items.map((item, idx) => (
                                    <li key={idx}>
                                        <a href={item.link}>{item.title}</a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div
                        className={cn(
                            "nav-right-content",
                            !showMenuItems ? "r0" : ""
                        )}
                    >
                        {closeButton && (
                            <Icon
                                name="cancel-circle"
                                className="navbarCloseIcon"
                                onClick={() => window.close()}
                            />
                        )}
                        {!closeButton && orderButton !== "hidden" && (
                            <button
                                className="navbarBtn"
                                onClick={() =>
                                    (window.location.href = "/#order")
                                }
                                disabled={
                                    orderButton === "disabled" ? true : false
                                }
                            >
                                Objednávka
                            </button>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
