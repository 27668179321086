import React from "react";

const Findout = () => {
    const publicUrl = process.env.PUBLIC_URL;

    return (
        <div className="findoutSection pd-default-120" id="findout">
            <div className="service-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10">
                            <div className="section-title text-center margin-bottom-90">
                                <h2
                                    className="title wow animated fadeInUp"
                                    data-wow-duration="0.6s"
                                    data-wow-delay="0.1s"
                                >
                                    Vďaka diagnostike účtovaných položiek za
                                    elektrinu sa dozviete
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-gutters-16">
                        <div className="col mx-1">
                            <div
                                className="single-service wow animated fadeInUp"
                                data-wow-duration="0.4s"
                                data-wow-delay="0.1s"
                            >
                                <div className="findoutItemCol">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/findOut01.png`}
                                        alt="payingExtra"
                                    />
                                    <h6>Neplatím niečo vo faktúrach navyše?</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col mx-1">
                            <div
                                className="single-service wow animated fadeInUp"
                                data-wow-duration="0.4s"
                                data-wow-delay="0.1s"
                            >
                                <div className="findoutItemCol">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/findOut02.png`}
                                        alt="saveOnDistribution"
                                    />
                                    <h6>Koľko viem ušetriť na distribúcii?</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col mx-1">
                            <div
                                className="single-service wow animated fadeInUp"
                                data-wow-duration="0.4s"
                                data-wow-delay="0.1s"
                                style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                }}
                            >
                                <div className="findoutItemCol">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/findOut03.png`}
                                        alt="circuitBreakerValue"
                                    />
                                    <h6>
                                        Mám vhodne nastavenú hodnotu ističa?
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col mx-1">
                            <div
                                className="single-service wow animated fadeInUp"
                                data-wow-duration="0.4s"
                                data-wow-delay="0.1s"
                            >
                                <div className="findoutItemCol">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/findOut04.png`}
                                        alt="whatChargingMe"
                                    />
                                    <h6>Čo mi účtuje dodávateľ energií?</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col mx-1">
                            <div
                                className="single-service wow animated fadeInUp"
                                data-wow-duration="0.4s"
                                data-wow-delay="0.1s"
                            >
                                <div className="findoutItemCol">
                                    <img
                                        src={`${publicUrl}/assets/img/icons/findOut05.png`}
                                        alt="unnecessaryFees"
                                    />
                                    <h6>Neplatím zbytočne jalovinu?</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Findout;
