import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import Col from "./Col";
import Input from "./energeticProtocolForm/Input";
import TextAreaInput, {
    TextAreaInputProps,
} from "./energeticProtocolForm/inputs/TextAreaInput";
import TextInput, {
    TextInputProps,
} from "./energeticProtocolForm/inputs/TextInput";
import Modal from "./Modal";
import Row from "./Row";
import { sendContactEmail } from "../helpers/fetchHelpers";
import Preloader from "./Preloader";

export interface ContactValues {
    name: string;
    company: string;
    phone: string;
    email: string;
    note: string;
}

const initContactVals = {
    name: "",
    company: "",
    phone: "",
    email: "",
    note: "",
};

interface ContactErrors {
    name?: string[];
    company?: string[];
    phone?: string[];
    email?: string[];
    note?: string[];
}

const initContactErrors = {
    name: undefined,
    company: undefined,
    phone: undefined,
    email: undefined,
    note: undefined,
};

interface Props {
    open: boolean;
    closeHandler: () => void;
}

const ContactModal: FC<Props> = ({ open, closeHandler }) => {
    const [contactVals, setContactVals] = useState<ContactValues>(
        initContactVals
    );
    const [errors, setErrors] = useState<ContactErrors>(initContactErrors);
    const [loading, setLoading] = useState(false);

    const validate = (vals: ContactValues) => {
        let valid = true;
        let newErrors = {};
        Object.entries(vals).forEach(([key, value]) => {
            if (!value) {
                valid = false;
                newErrors = { ...newErrors, [key]: ["Pole je povinné"] };
            }
        });
        const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (vals.email && !vals.email.match(email_regex)) {
            valid = false;
            newErrors = {
                ...newErrors,
                email: ["Emailová adresa je neplatná"],
            };
        }
        setErrors(newErrors);
        return valid;
    };

    const submitHandle = () => {
        if (validate(contactVals)) {
            setLoading(true);
            sendContactEmail(contactVals)
                .then(() => {
                    setLoading(false);
                    toast("Ďakujeme, čoskoro sa Vám ozveme.");
                    closeHandler();
                    setContactVals(initContactVals);
                })
                .catch(() => {
                    setLoading(false);
                    toast(
                        "Pri odosielaní formulára nastala chyba. Skúste odoslať formulár znova alebo nám napíšte na email: helpdesk@endia.sk"
                    );
                    closeHandler();
                });
        } else {
        }
    };

    return (
        <Modal
            classNames="contactModal"
            width="1000px"
            padding="50px 50px"
            open={open}
            closeHandler={closeHandler}
            fadeOutOnClose={true}
        >
            <Preloader show={loading} global={false} />
            <h1>Napíšte nám</h1>
            Prosím vyplňte krátky formulár a my sa Vám čoskoro ozveme,
            <br />
            alebo nám zavolajte <span className="f-cyan">0910 47 45 00</span>
            <Row gap="5px" wrap="wrap">
                <Col width={1} className="contactModalInputCol">
                    <Input<TextInputProps>
                        component={TextInput}
                        errors={errors.name}
                        label={
                            <>
                                Meno a priezvisko{" "}
                                <span className="f-pink">*</span>
                            </>
                        }
                        id="contactName"
                        value={contactVals.name}
                        onChange={(val) =>
                            setContactVals({ ...contactVals, name: val })
                        }
                        onBlur={() => setErrors({ ...errors, name: undefined })}
                    />
                </Col>
                <Col width={1} className="contactModalInputCol">
                    <Input<TextInputProps>
                        component={TextInput}
                        errors={errors.phone}
                        label={
                            <>
                                Telefón <span className="f-pink">*</span>
                            </>
                        }
                        id="contactPhone"
                        value={contactVals.phone}
                        onChange={(val) =>
                            setContactVals({ ...contactVals, phone: val })
                        }
                        onBlur={() =>
                            setErrors({ ...errors, phone: undefined })
                        }
                    />
                </Col>
            </Row>
            <Row gap="5px" wrap="wrap">
                <Col width={1} className="contactModalInputCol">
                    <Input<TextInputProps>
                        component={TextInput}
                        errors={errors.company}
                        label={
                            <>
                                Názov spoločnosti{" "}
                                <span className="f-pink">*</span>
                            </>
                        }
                        id="contactCompany"
                        value={contactVals.company}
                        onChange={(val) =>
                            setContactVals({ ...contactVals, company: val })
                        }
                        onBlur={() =>
                            setErrors({ ...errors, company: undefined })
                        }
                    />
                </Col>
                <Col width={1} className="contactModalInputCol">
                    <Input<TextInputProps>
                        component={TextInput}
                        errors={errors.email}
                        label={
                            <>
                                Email <span className="f-pink">*</span>
                            </>
                        }
                        id="contactEmail"
                        value={contactVals.email}
                        onChange={(val) =>
                            setContactVals({ ...contactVals, email: val })
                        }
                        onBlur={() =>
                            setErrors({ ...errors, email: undefined })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input<TextAreaInputProps>
                        component={TextAreaInput}
                        errors={errors.note}
                        label={
                            <>
                                Poznámka <span className="f-pink">*</span>
                            </>
                        }
                        id="contactNote"
                        value={contactVals.note}
                        onChange={(val) =>
                            setContactVals({ ...contactVals, note: val })
                        }
                        onBlur={() => setErrors({ ...errors, note: undefined })}
                    />
                </Col>
            </Row>
            <Row className="contactModalInfo" justify="flex-end">
                * pole je povinné
            </Row>
            <Row className="contactModalButtonContainer">
                <button className="button pink" onClick={submitHandle}>
                    Odoslať
                </button>
            </Row>
        </Modal>
    );
};

export default ContactModal;
