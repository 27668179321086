import React, { useEffect } from "react";
import DocumentMeta from "react-document-meta";

import Navbar from "../Navbar";
import Footer from "../Footer";

const VOP = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DocumentMeta
            {...{
                title: "Energetická diagnostika - Všeobecné obchodné podmienky",
            }}
        >
            <Navbar showMenuItems={false} closeButton={true} />
            <div className="blog-page-area pd-top-120">
                <div className="container">
                    <div className="row custom-gutters-60">
                        <div className="gdpr-vop-container col-lg-12">
                            <h2>Všeobecné obchodné podmienky</h2>
                            <h3>Článok I.</h3>
                            <h4>Úvodné ustanovenia</h4>
                            <ol>
                                <li>
                                    Tieto obchodné podmienky upravujú práva a
                                    povinnosti medzi poskytovateľom a
                                    potencionálnym klientom alebo klientom pri
                                    poskytovaní služieb uvedených v čl. III.
                                    týchto všeobecných obchodných podmienok.
                                    Poskytovateľom služieb je obchodná
                                    spoločnosť Energo audit, s.r.o., so sídlom
                                    Kozmonautov 3/A, 949 01 Nitra, IČO: 50 341
                                    120, DIČ: 2120 290 579, IČ DPH: SK2120 290
                                    579, ktorá je zapísaná v Obchodnom registri
                                    Okresného súdu Nitra, oddiel: Sro, vložka
                                    číslo: 40944/N (ďalej aj ako „Poskytovateľ“
                                    alebo „Prevádzkovateľ“). Poskytovateľ je
                                    platiteľom dane z pridanej hodnoty.
                                </li>
                                <li>
                                    Konateľom Poskytovateľa, a zároveň
                                    zodpovednou osobou je Rudolf Korman.
                                </li>
                                <li>
                                    Poskytovateľ je prevádzkovateľom webovej
                                    stránky www.endia.sk (ďalej aj ako
                                    „Stránka“), ktorá umožňuje prístup do
                                    Systému energetickej diagnostiky. Systém
                                    poskytuje diagnostiku účtovaných položiek za
                                    elektrinu (najmä v rozsahu MRK, RK, jalový
                                    odber, jalová dodávka). Systém Stránky je
                                    rozdelený na neplatenú a platenú časť.
                                </li>
                                <li>
                                    Klientom je fyzická alebo právnická osoba,
                                    ktorá navštívi Stránku a zakúpi si
                                    poskytnutie služby uvedenej v čl. III, na
                                    základe čoho získa prístup do platenej časti
                                    systému Stránky (ďalej aj ako „klient“ alebo
                                    „používateľ“ alebo "objednávateľ").
                                </li>
                                <li>
                                    Potencionálnym klientom je fyzická alebo
                                    právnická osoba, ktorá navštívi Stránku a
                                    využíva iba neplatenú časť systému Stránky.
                                </li>
                                <li>
                                    Orgánom dozoru je Slovenská obchodná
                                    inšpekcia (ďalej len „SOI“), Inšpektorát SOI
                                    pre Nitriansky kraj Staničná 9, P. O. BOX
                                    49/A, 950 50 Nitra 1.
                                </li>
                                <li>
                                    Poskytovateľ poskytuje klientom a
                                    potencionálnym klientom zákaznícku podporu
                                    cestou e-mailovej komunikácie cez adresu{" "}
                                    <b>helpdesk@endia.sk.</b>
                                </li>
                            </ol>
                            <h3>Článok II.</h3>
                            <h4>
                                Služba analýzy účtovaných položiek za elektrinu
                            </h4>
                            <ol>
                                <li>
                                    Systém je rozdelený na neplatenú a platenú
                                    časť. Neplatená časť je voľne prístupná
                                    každému potencionálnemu klientovi bez
                                    akéhokoľvek poplatku. Táto neplatená časť
                                    systému poskytuje okrem iného prehľad
                                    informácií o poskytovanej službe, ktorá bude
                                    dostupná po zaplatení ceny za poskytnutie
                                    služby, ktorá je uvedená na Stránke
                                    Poskytovateľa. Platená časť systému
                                    poskytuje klientom konkrétne informácie o
                                    diagnostike účtovaných položiek za
                                    elektrinu, a to najmä v rozsahu MRK, RK,
                                    jalová dodávka, jalový odber. Údaje uvádzané
                                    v platenej a neplatenej časti systému sú
                                    uvedené v článku III. týchto všeobecných
                                    obchodných podmienok.
                                </li>
                                <li>
                                    Tento systém je prístupný potencionálnym
                                    klientom a klientom Poskytovateľa
                                    prostredníctvom internetu na Stránke 24
                                    hodín denne, s výnimkou krátkodobých
                                    technických odstávok nutných pre pravidelnú
                                    údržbu systému alebo obdobných technických
                                    výpadkov.
                                </li>
                                <li>
                                    Poskytovaná služba predstavuje diagnostiku
                                    účtovaných položiek za elektrinu:
                                    <ul>
                                        <li>
                                            V rozsahu hodnotenia: MRK (Maximálna
                                            rezervovaná kapacita); RK
                                            (Rezervovaná kapacita); Jalový
                                            odber; Jalová dodávka
                                        </li>
                                        <li>
                                            S výsledkom hodnotenia: Vyhovuje;
                                            Nevyhovuje; V tolerancii;
                                            Predimenzované
                                        </li>
                                        <li>S kalkulovanou úsporou</li>
                                        <li>S návrhmi na nápravné opatrenia</li>
                                    </ul>
                                </li>
                                <li>
                                    Poskytovateľ dodá klientovi vyhotovený
                                    výstup - Protokol energetickej diagnostiky
                                    vo formáte PDF.
                                </li>
                            </ol>
                            <h3>Článok III.</h3>
                            <h4>
                                Zmluva o poskytnutí služby. Rozsah poskytovaných
                                služieb
                            </h4>
                            <ol>
                                <li>
                                    Zmluvný vzťah medzi Poskytovateľom a
                                    klientom vzniká na základe vopred
                                    elektronicky zadanej objednávky zo strany
                                    klienta a následným zaplatením ceny za
                                    poskytnutie služby uvedenej nižšie. Tieto
                                    všeobecné obchodné podmienky tvoria
                                    neoddeliteľnú súčasť zmluvy, pričom
                                    vyjadrenie súhlasu s týmito obchodnými
                                    podmienkami ešte pred zaplatením ceny za
                                    poskytnutie služby je nevyhnutným
                                    predpokladom na uzatvorenie zmluvy.
                                </li>
                                <li>
                                    Na základe uzatvoreného právneho vzťahu je
                                    Poskytovateľ povinný poskytnúť klientovi
                                    služby, za ktoré zaplatil, a to{" "}
                                    <u>do piatich pracovných dní</u> po vykonaní
                                    platby v súlade s týmito všeobecnými
                                    obchodnými podmienkami a doloženia všetkých
                                    požadovaných dokumentov potrebných na
                                    spracovanie služby.
                                </li>
                                <li>
                                    Poskytnutie zaplatenej služby spočíva v tom,
                                    že Poskytovateľ na základe jemu dostupných
                                    informácií, sprístupní klientovi v
                                    spoplatnenej časti systému vybrané údaje
                                    súvisiace s diagnostikou účtovaných položiek
                                    za elektrinu.
                                </li>
                                <li>
                                    Poskytovateľ sprístupňuje údaje o
                                    dopytovanom odbernom mieste na základe
                                    zadania potrebných údajov do formulára
                                    dostupnom na webovom sídle, a to v rozsahu -
                                    Emailová adresa, Meno a Priezvisko,
                                    Telefónne číslo, IČO spoločnosti, Názov
                                    spoločnosti, EIC odberného miesta, DIČ, IČ
                                    DPH, Adresa - Ulica a číslo, Obec, PSČ,
                                    Posudzované obdobie, Začiatok posudzovaného
                                    obdobia.
                                </li>
                                <li>
                                    Pre vytvorenie protokolu energetickej
                                    diagnostiky je následne potrebné priložiť
                                    súbory s faktúrami za elektrinu za zvolený
                                    počet po sebe idúcich mesiacov.
                                </li>
                                <li>
                                    Po doložení kompletných dokumentov, ktoré
                                    budú Poskytovateľom vyhodnotené ako
                                    použiteľné, dôjde k vypracovaniu protokolu
                                    energetickej diagnostiky, ktorý bude
                                    klientovi sprístupnený vo forme súboru .pdf.
                                </li>
                                <li>
                                    Poskytovateľ upozorňuje potencionálneho
                                    klienta a klienta, že akékoľvek údaje o
                                    poskytnutej službe, ktoré získal na základe
                                    prístupu do systému, slúžia výlučne na
                                    informatívny účel a tieto údaje nemôžu byť
                                    použité na komerčné účely a tak isto nie sú
                                    použiteľné pre právne úkony.
                                </li>
                            </ol>
                            <h3>Článok IV.</h3>
                            <h4>Platby</h4>
                            <ol>
                                <li>
                                    Jednotková cena za poskytnutie služby je
                                    4,90 Eur bez DPH za analyzovaný mesiac.
                                    Úhrada za zvolené služby prebieha
                                    prostredníctvom platobnej brány
                                    registrovanej pre webové sídlo.
                                </li>
                                <li>
                                    Používateľ poskytuje dobrovoľne
                                    Prevádzkovateľovi osobné údaje podľa
                                    Nariadenia Európskeho parlamentu a Rady (EÚ)
                                    2016/679 z 27. apríla 2016 o ochrane
                                    fyzických osôb pri spracúvaní osobných
                                    údajov a o voľnom pohybe takýchto údajov,
                                    ktorým sa zrušuje smernica 95/46/ES
                                    (všeobecné nariadenie o ochrane údajov)
                                    (ďalej len: „nariadenie GDPR“) a zákona č.
                                    18/2018 Z.z. o ochrane osobných údajov a o
                                    zmene a doplnení niektorých zákonov (ďalej
                                    lej „Zákon o ochrane osobných údajov“), za
                                    účelom poskytovania služieb na Portáli.
                                    Neposkytnutie osobných údajov Používateľom
                                    znamená nemožnosť uzatvorenia zmluvného
                                    vzťahu a poskytovania služieb. Doba
                                    uchovávania osobných údajov je 10 rokov odo
                                    dňa skončenia zmluvného vzťahu.
                                </li>
                                <li>
                                    V prípade, ak by potencionálny klient alebo
                                    klient chcel Poskytovateľovi zaplatiť cenu
                                    za poskytnutie služby iným spôsobom ako
                                    platobnou bránou dostupnou na Stránke
                                    Poskytovateľa, potencionálny klient je
                                    oprávnený kontaktovať Poskytovateľa na
                                    e-mailovej adrese: <b>helpdesk@endia.sk</b>
                                </li>
                                <li>
                                    Po vykonaní platby Poskytovateľ zašle
                                    klientovi na e-mail uvedený vo formulári
                                    potvrdenie o platbe, v ktorom uvedie svoje
                                    identifikačné údaje, identifikáciu klienta,
                                    identifikačné číslo objednávky, dátum a čas
                                    uskutočnenia objednávky, názov zaplatenej
                                    služby, a celkovú cenu objednanej služby.
                                </li>
                                <li>
                                    V prípade, ak klient bude chcieť kontaktovať
                                    Poskytovateľa, po odoslaní objednávky, môže
                                    využiť zákaznícku podporu uvedenú v bode 7
                                    čl. I. týchto všeobecných obchodných
                                    podmienok.
                                </li>
                            </ol>
                            <h3>Článok V.</h3>
                            <h4>Cenník a zľavy</h4>
                            <ol>
                                <li>
                                    Pre účely cenníka Poskytovateľa sa pod
                                    pojmom poskytnutie služby uvedenej v čl. III
                                    týchto všeobecných obchodných podmienok
                                    rozumie „Diagnostika účtovaných položiek za
                                    elektrinu “. Ceny uvedené na Stránke sú
                                    konečné a k týmto cenám Poskytovateľ nebude
                                    prirátavať žiadne poplatky.
                                </li>
                                <li>
                                    Cena za poskytnutie jednej služby je 4,90 €
                                    bez DPH za mesiac.
                                </li>
                                <li>
                                    Klient po uhradení ceny za službu, môže túto
                                    službu využiť do 1 mesiaca odo dňa uhradenia
                                    tejto ceny. Po tejto dobe nie je možné
                                    zaplatenú službu využiť. V prípade, ak
                                    klient nevyužije zaplatenú službu do tejto
                                    doby, Poskytovateľ cenu za zaplatenú ale
                                    nevyužitú službu klientovi nevráti.
                                </li>
                                <li>
                                    Cena za poskytnutie služby môže byť znížená
                                    prostredníctvom zadania zľavového kódu,
                                    ktorý Poskytovateľ poskytuje svojim
                                    Partnerom tj. iným právnickým osobám, ktorí
                                    tento kód môžu voľne šíriť za účelom
                                    poskytnutia zvýhodnenej služby.
                                </li>
                            </ol>
                            <h3>Článok VI.</h3>
                            <h4>
                                Zodpovednosť Poskytovateľa za poskytnuté služby.
                                Reklamácie
                            </h4>
                            <ol>
                                <li>
                                    Poskytovateľ upozorňuje potencionálnych
                                    klientov a klientov, že za účelom
                                    poskytnutia maximálneho množstva informácií
                                    vo vzťahu k dopytovaným službám,
                                    poskytovateľ spolupracuje s tretími osobami
                                    – partnermi pôsobiacimi v Slovenskej
                                    republike, ako aj v zahraničí. Ide
                                    predovšetkým o partnerov z oblasti energií a
                                    energetiky.
                                </li>
                                <li>
                                    Poskytovateľ upozorňuje potencionálnych
                                    klientov a klientov, že informácie uvedené v
                                    službe sú poskytované aj na základe
                                    informácií, ktoré majú k dispozícii partneri
                                    uvedení v bode 1 bez možnosti Poskytovateľa
                                    overiť ich správnosť a úplnosť. Poskytovateľ
                                    neberie na seba žiadnu zodpovednosť za
                                    správnosť a úplnosť informácií uvedených v
                                    službe.
                                </li>
                                <li>
                                    Na základe a v súvislosti s informáciami
                                    uvedenými v bode 1 a 2 klient je oprávnený
                                    podať Poskytovateľovi reklamáciu
                                    elektronicky na e-mail{" "}
                                    <b>helpdesk@endia.sk</b>, osobne alebo
                                    doporučenou poštou na adresu sídla
                                    Poskytovateľa, a to bezodkladne, a to najmä
                                    v súvislosti s tým, ak Poskytovateľ
                                    neposkytne klientovi zaplatenú službu po
                                    tom, čo klient požiadal o jej poskytnutie,
                                    služba bude nedostupná, a zároveň
                                    Poskytovateľ klientovi vopred neoznámil, že
                                    služba nebude dostupná z technických dôvodov
                                    a na aký dlhý čas. V prípade uplatnenia
                                    reklamácie Poskytovateľ vydá klientovi
                                    potvrdenie o prijatí reklamácie.
                                </li>
                                <li>
                                    Poskytovateľ je povinný určiť spôsob
                                    vybavenia reklamácie ihneď, v zložitých
                                    prípadoch najneskôr do 3 pracovných dní od
                                    prijatia reklamácie, v odôvodnených
                                    prípadoch najneskôr do 30 dní odo dňa
                                    prijatia reklamácie. Po určení spôsobu
                                    vybavenia reklamácie vybaví poskytovateľ
                                    reklamáciu ihneď, v odôvodnených prípadoch
                                    najneskôr do 30 dní od prijatia reklamácie.
                                    Výsledkom reklamačného konania môže byť
                                    odmietnutie reklamácie, dodatočné
                                    poskytnutie služby alebo vrátenie
                                    zaplatených peňazí klientovi. O vybavení
                                    reklamácie Poskytovateľ je povinný vydať
                                    písomný doklad. V prípade, ak klient oznámi
                                    Poskytovateľovi, že nemá záujem na
                                    poskytnutí služby, a Poskytovateľ uzná
                                    reklamáciu za oprávnenú, Poskytovateľ vráti
                                    klientovi bez akéhokoľvek poplatku finančné
                                    prostriedky vo výške ceny, ktorú klient
                                    zaplatil za poskytnutie reklamovanej služby,
                                    a to najneskôr do 7 kalendárnych dní od
                                    vybavenia reklamácie. V prípade, ak
                                    výsledkom reklamačného konania bude
                                    dodatočné sprístupnenie služby klientovi,
                                    Poskytovateľ túto službu poskytne klientovi
                                    okamžite po vybavení reklamácie.
                                </li>
                                <li>
                                    Doklad o vybavení reklamácie zasiela
                                    Poskytovateľ klientovi na jeho e-mail
                                    uvedený vo formulári, okrem prípadu, ak by
                                    klient výslovne oznámil Poskytovateľovi, že
                                    žiada zaslať doklad o vybavení reklamácie na
                                    ním uvedenú adresu.
                                </li>
                                <li>
                                    Poskytovateľ je oprávnený reklamáciu
                                    zamietnuť, iba ak by zistil, že (I)
                                    klientovi bola zaplatená služba riadne
                                    poskytnutá, (II) Poskytovateľ informoval
                                    klienta, že službu nebude možné využiť z
                                    technických dôvodov v určitom časovom
                                    horizonte, (III) na strane Poskytovateľa
                                    nedošlo k žiadnemu technickému zlyhaniu (IV)
                                    na strane Poskytovateľa nedošlo k pochybeniu
                                    pri poskytovaní služby.
                                </li>
                                <li>
                                    V prípade, ak Poskytovateľ nevybaví
                                    reklamáciu v lehote uvedenej v týchto
                                    všeobecných obchodných podmienkach, klient
                                    je oprávnený od tejto zmluvy odstúpiť, a to
                                    elektronicky na e-mail:{" "}
                                    <b>helpdesk@endia.sk</b> spôsobom uvedeným v
                                    bode 2 čl. VII. týchto všeobecných
                                    obchodných podmienok.
                                </li>
                            </ol>
                            <h3>Článok VII.</h3>
                            <h4>Právo odstúpiť od zmluvy</h4>
                            <ol>
                                <li>
                                    Okrem prípadu uvedeného v bode 7 čl. VI.
                                    týchto všeobecných obchodných podmienok
                                    klient má právo odstúpiť od zmluvy do 14 dní
                                    od zaplatenia ceny za poskytnutie služby, a
                                    to aj bez uvedenia dôvodu, a to elektronicky
                                    na e-mail: <b>helpdesk@endia.sk</b>. Lehota
                                    sa považuje za zachovanú aj v tom prípade,
                                    ak v posledný deň lehoty klient zaslal
                                    odstúpenie od zmluvy na vyššie uvedený
                                    e-mail Poskytovateľa.
                                </li>
                                <li>
                                    V odstúpení musí byť uvedený dátum, meno
                                    (resp. obchodné meno) klienta, sídlo
                                    klienta, ktorý odstupuje od zmluvy s
                                    nasledovným textom: „týmto odstupujem od
                                    zmluvy o poskytnutí služby, ktorú som si
                                    zaplatil u Poskytovateľa dňa
                                    ..............“. Poskytovateľ akceptuje aj
                                    akýkoľvek iný prejav, z ktorého bude
                                    jednoznačná vôľa klienta odstúpiť od zmluvy.
                                </li>
                                <li>
                                    V prípade, ak klient zaplatí cenu za službu
                                    podľa Cenníka Poskytovateľa a po tomto
                                    zaplatení úplne využije službu, ktorú
                                    zaplatil, platí, že Poskytovateľ poskytol
                                    túto službu klientovi s jeho výslovným
                                    súhlasom. Poskytovateľ klienta poučuje, že
                                    vyjadrením tohto súhlasu, klient stráca
                                    právo na odstúpenie od zmluvy po úplnom
                                    poskytnutí služby, ak došlo k úplnému
                                    poskytnutiu zaplatenej služby.
                                </li>
                                <li>
                                    V prípade, ak klient odstúpi od zmluvy v
                                    lehote uvedenej v bode 1 a nevyužije
                                    zaplatenú službu, Poskytovateľ mu vráti
                                    cenu, ktorú klient zaplatil, a to do 7
                                    kalendárnych dní od doručenia odstúpenia od
                                    zmluvy. Poskytovateľ vráti peniaze klientovi
                                    bez akéhokoľvek poplatku, a to na bankový
                                    účet, z ktorého klient zaplatil cenu za
                                    službu.
                                </li>
                                <li>
                                    Klient nemôže odstúpiť od zmluvy, predmetom
                                    ktorej je poskytovanie elektronického obsahu
                                    inak ako na hmotnom nosiči, ak sa jeho
                                    poskytovanie začalo s výslovným súhlasom
                                    spotrebiteľa a spotrebiteľ vyhlásil, že bol
                                    riadne poučený o tom, že vyjadrením tohto
                                    súhlasu stráca právo na odstúpenie od
                                    zmluvy.
                                </li>
                                <li>
                                    Ustanovenia čl. VII. týchto všeobecných
                                    obchodných podmienok výslovne neplatia pre
                                    subjekty nespĺňajúce definíciu spotrebiteľa
                                    uvedenú v ust. § 2 písm. a) Zákona 250/2007
                                    Z.z. o ochrane spotrebiteľa.
                                </li>
                            </ol>
                            <h3>Článok VIII.</h3>
                            <h4>Cookies</h4>
                            <ol>
                                <li>
                                    Stránka využíva tzv. cookies. Sú to malé
                                    textové súbory, ktoré sa na počítači
                                    dotknutej osoby ukladajú do špeciálnej
                                    schránky prehliadača. Vďaka ich využívaniu,
                                    ktoré je však anonymné, je možné
                                    plnohodnotne využívať všetky funkcie
                                    Stránky. Cookies uľahčujú tak používanie
                                    Stránky a zlepšujú jej výkon a funkčnosť.
                                    Ide najmä o sledovanie správania používateľa
                                    a následné prispôsobenie obsahu,
                                    zjednodušenie používania Stránky, možnosť
                                    prihlasovania, prispôsobenie a obmedzenie
                                    reklamných kampaní a podobné funkcionality,
                                    ktoré by bez využitia cookies neboli možné.
                                </li>
                                <li>
                                    Dotknutá osoba má právo zmenou nastavenia vo
                                    svojom internetovom prehliadači, samozrejme,
                                    využívanie cookies zakázať. Ak dotknutá
                                    osoba neakceptuje žiadne cookies, nebude
                                    môcť využívať kompletnú funkčnosť a všetky
                                    ponuky Stránky.
                                </li>
                                <li>
                                    Poskytovateľ je oprávnený sledovať a
                                    uchovávať IP adresy užívateľov – klientov, a
                                    to výhradne pre účely evidencie prístupu na
                                    Stránku. IP adresy nie sú v žiadnom prípade
                                    poskytované tretím osobám a na ich
                                    zabezpečenie používa Poskytovateľ všetky
                                    potrebné bezpečnostné opatrenia podľa
                                    interného bezpečnostného projektu. V rámci
                                    tohto procesu je IP adresa užívateľa -
                                    klienta zaznamenaná iba v skrátenej podobe,
                                    identifikovať konkrétne pripojenie tak nie
                                    je v žiadnom prípade možné. Identita
                                    užívateľa - klienta teda zostáva neznáma.
                                    Užívateľ má právo na informácie o použití IP
                                    adries.
                                </li>
                                <li>
                                    Poskytovateľ zaznamenáva aj informácie,
                                    ktoré poskytne internetový prehliadač
                                    dotknutej osoby v súvislosti s využívaním
                                    Stránky (okrem iného aj názov vyvolaného
                                    súboru, dátum a čas dopytu, množstvo
                                    prenesených údajov, hlásenie úspešného
                                    dopytu, typ internetového prehliadača, jazyk
                                    a doménu stránky, z ktorej sa dotknutá osoba
                                    na portál dostala), za účelom získania
                                    štatistických údajov o využívaní a
                                    efektívnosti Stránky. Poskytovateľ
                                    registruje každý prístup na Stránku a každý
                                    dopyt na umiestnené súbory.
                                </li>
                            </ol>
                            <h3>Článok IX.</h3>
                            <h4>Spracovanie a ochrana osobných údajov</h4>
                            <ol>
                                <li>
                                    Ochrana osobných údajov je upravená v
                                    samostatnom dokumente, ktorý je prístupný na
                                    webovom sídle. Pred odoslaním objednávky
                                    klient bude vyzvaný, aby zaškrtnutím políčka
                                    potvrdil, že sa oboznámil so spracovaním,
                                    ochranou osobných údajov a, že dáva súhlas s
                                    poskytnutím jeho osobných údajov.
                                    Poskytovateľ nebude žiadnym spôsobom
                                    vynucovať poskytnutie osobných údajov od
                                    potencionálnych klientov a klientov.
                                </li>
                                <li>
                                    Používateľ poskytuje dobrovoľne
                                    Prevádzkovateľovi osobné údaje podľa
                                    Nariadenia Európskeho parlamentu a Rady (EÚ)
                                    2016/679 z 27. apríla 2016 o ochrane
                                    fyzických osôb pri spracúvaní osobných
                                    údajov a o voľnom pohybe takýchto údajov,
                                    ktorým sa zrušuje smernica 95/46/ES
                                    (všeobecné nariadenie o ochrane údajov)
                                    (ďalej len: „nariadenie GDPR“) a zákona č.
                                    18/2018 Z.z. o ochrane osobných údajov a o
                                    zmene a doplnení niektorých zákonov (ďalej
                                    lej „Zákon o ochrane osobných údajov“), za
                                    účelom poskytovania služieb na Portáli.
                                    Prevádzkovateľ spracúva osobné údaje
                                    Používateľa na účely inzercie a s tým
                                    súvisiacich služieb na základe zmluvy podľa
                                    čl. 6 ods. 1 písm. b) nariadenia GDPR.
                                    Neposkytnutie osobných údajov Používateľom
                                    znamená nemožnosť uzatvorenia zmluvného
                                    vzťahu a poskytovania služieb, ktoré súvisia
                                    s inzerciou. Doba uchovávania osobných
                                    údajov je 10 rokov odo dňa skončenia
                                    zmluvného vzťahu.
                                </li>
                                <li>
                                    Prevádzkovateľ Používateľa informuje, že v
                                    zmysle § 62 ods. 3 zákona č. 351/2011 Z.z. o
                                    elektronických komunikáciách v znení
                                    neskorších právnych predpisov (ďalej len
                                    „Zákon o elektronických komunikáciách“) ho
                                    môže oslovovať s volaniami, so zasielaním
                                    e-mailov a SMS správ na kontaktné adresy,
                                    ktoré uviedol vo formulári na účely priameho
                                    marketingu vlastných podobných tovarov a
                                    služieb. Volanie, e-mail alebo SMS správa
                                    podľa predchádzajúcej vety môže mať
                                    informačný alebo reklamný charakter a ich
                                    pôvodcom je Prevádzkovateľ. Prevádzkovateľ
                                    bude spracúvať osobné údaje po dobu trvania
                                    zmluvného vzťahu. Nesúhlas s volaním, so
                                    zasielaním týchto e-mailov alebo SMS správ
                                    môže Používateľ kedykoľvek oznámiť
                                    Prevádzkovateľovi zaslaním e-mailu na
                                    kontaktnú adresu. Prevádzkovateľ bude
                                    poskytovať osobné údaje na účely priameho
                                    marketingu týmto príjemcom: Slovenská
                                    obchodná inšpekcia, súd, orgány činné v
                                    trestnom konaní a ďalšie subjekty, ktorým je
                                    Prevádzkovateľ povinný poskytnúť osobné
                                    údaje na základe zákona, ďalej budú dané
                                    údaje sprístupnené Prevádzkovateľovi,
                                    správcom serveru, účtovníkovi.
                                </li>
                                <li>
                                    Používateľ poskytuje svoje osobné údaje
                                    dobrovoľne a je si vedomý toho, že jeho
                                    osobné údaje môžu byť v súvislosti so
                                    službami poskytovanými na serveroch
                                    sprístupnené príjemcom - tretím osobám –
                                    iným používateľom za účelom poskytovania
                                    služieb Portálu. Používateľ, ktorého osobné
                                    údaje sú spracúvané Prevádzkovateľom, má
                                    právo od Prevádzkovateľa požadovať prístup k
                                    osobným údajom, ktoré sa ho týkajú, ako aj
                                    právo na opravu, vymazanie alebo obmedzenie
                                    spracúvania týchto údajov. Používateľ má
                                    súčasne právo namietať proti spracúvaniu
                                    osobných údajov, ktoré sa ho týkajú a právo
                                    na prenosnosť týchto údajov. Ak sú žiadosti
                                    Používateľa v postavení dotknutej osoby
                                    zjavne neopodstatnené alebo neprimerané,
                                    najmä pre ich opakujúcu sa povahu,
                                    Prevádzkovateľ môže požadovať primeraný
                                    poplatok zohľadňujúci administratívne
                                    náklady na poskytnutie informácií alebo
                                    odmietnuť konať na základe žiadosti.
                                </li>
                                <li>
                                    Údaje o Používateľoch Portálu použije
                                    Prevádzkovateľ iba v medziach Zákona o
                                    ochrane osobných údajov. Prevádzkovateľ
                                    uchováva len meno a priezvisko, e-mailovú
                                    adresu a telefónne číslo Používateľa, názov
                                    spoločnosti, IČO a adresu, prípadne ďalšie
                                    údaje, ktoré si Používateľ zadal sám pri
                                    vyplnení formuláru alebo používaní služieb.
                                </li>
                                <li>
                                    Prevádzkovateľ je povinný zabezpečiť osobné
                                    údaje pred ich sprístupnením neoprávneným
                                    osobám. Povinnosťou prevádzkovateľa je
                                    oznámiť každé podstatnejšie narušenie či
                                    únik osobných údajov Úradu na ochranu
                                    osobných údajov, a to najneskôr do 72 hodín
                                    od zistenia takéhoto bezpečnostného
                                    incidentu.
                                </li>
                                <li>
                                    Používateľ má pri podozrení o neoprávnenom
                                    spracovaní jeho osobných údajov
                                    prevádzkovateľom právo podať návrh na
                                    začatie konania o ochrane osobných údajov
                                    Úradu na ochranu osobných údajov Slovenskej
                                    republiky.
                                </li>
                                <li>
                                    Dotknutá osoba môže svoj súhlas so
                                    spracovaním osobných údajov kedykoľvek
                                    písomne odvolať zaslaním Odvolania so
                                    spracovaním osobných údajov
                                    prevádzkovateľovi poštou alebo e-mailom.
                                </li>
                            </ol>
                            <h3>Článok X.</h3>
                            <h4>Záverečné ustanovenia</h4>
                            <ol>
                                <li>
                                    Klient bude pred odoslaním objednávky
                                    vyzvaný, aby zaškrtnutím políčka potvrdil,
                                    že sa s týmito obchodnými podmienkami
                                    oboznámil, prečítal ich, porozumel ich
                                    obsahu a v celom rozsahu s nimi súhlasí.
                                </li>
                                <li>
                                    Prevádzkovateľ má právo meniť, aktualizovať
                                    a inak modifikovať služby webového sídla bez
                                    predchádzajúceho upozornenia Používateľov
                                    ako aj bez ich súhlasu, v prípade, že takáto
                                    zmena nie je porušením práv Používateľov.
                                </li>
                                <li>
                                    Prevádzkovateľ je oprávnený kedykoľvek meniť
                                    ustanovenia týchto Podmienok z technických
                                    dôvodov, legislatívnych zmien alebo z dôvodu
                                    rozšírenia, zúženia alebo inej zmeny
                                    poskytovaných služieb na portáli alebo z
                                    dôvodu potrieb Prevádzkovateľa. V prípade,
                                    že dôjde k zmene Podmienok, Používateľ berie
                                    na vedomie a súhlasí s tým, že platná a
                                    účinná je vždy posledná verzia Podmienok.
                                    Používateľ je oprávnený sa kedykoľvek
                                    oboznámiť s aktuálnym znením týchto
                                    Podmienok. Ak Používateľ pokračuje v
                                    používaní Služieb po prevedení týchto zmien
                                    Prevádzkovateľom, má sa za to, že so zmenami
                                    pravidiel bez výhrad súhlasí.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </DocumentMeta>
    );
};

export default VOP;
