import React, { FC } from "react";

interface Props {
    open: boolean;
    submitBtnHandler?: (e: any) => void;
    submitBtnText?: string;
    cancelBtnHandler?: (e: any) => void;
    cancelBtnText?: string;
}

const PopUpPanel: FC<Props> = ({
    open,
    submitBtnHandler,
    submitBtnText,
    cancelBtnHandler,
    cancelBtnText,
    ...props
}) => {
    return (
        <div
            className="popUpPanel"
            style={{ display: open ? "block" : "none" }}
        >
            {props.children}
            <div className="popUpPanelButtons">
                {cancelBtnHandler && (
                    <button onClick={cancelBtnHandler}>
                        {cancelBtnText || "Zrušiť"}
                    </button>
                )}
                {submitBtnHandler && (
                    <button onClick={submitBtnHandler}>
                        {submitBtnText || "Odoslať"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default PopUpPanel;
