import React, { useState } from "react";

import Row from "../../Row";
import { useLocation } from "react-router-dom";
import Input from "../../energeticProtocolForm/Input";
import TextAreaInput, {
    TextAreaInputProps,
} from "../../energeticProtocolForm/inputs/TextAreaInput";
import Col from "../../Col";
import { config } from "../../../config";
import { sendEmail } from "../../../helpers/fetchHelpers";
import { toast } from "react-toastify";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PaymentFail = () => {
    const [message, setMessage] = useState("");
    const [showSupportMsgInput, setShowSupportMsgInput] = useState(false);
    const description = useQuery().get("description")?.replace("+", " ");
    const uuid = useQuery().get("order_uuid");
    const orderNumber = useQuery().get("number");
    const publicUrl = process.env.PUBLIC_URL;

    const handleRePayBtnClick = () => {
        window.location.href = `${config.server.url}/payment/order/${uuid}/pay`;
    };

    const sendEmailToSupport = () => {
        uuid &&
            sendEmail(
                uuid,
                config.emailTypeCodes.support_contact_email,
                message
            )
                .then(() => {
                    setShowSupportMsgInput(false);
                    toast(
                        "Vaša pripomienka bola odoslaná. Naši pracovníci Vás budú kontaktovať pre vyriešenie daného problému."
                    );
                })
                .catch(() => {
                    toast(
                        "Pri odosielaní formulára nastala chyba. Skúste odoslať formulár znova alebo nám napíšte na email: helpdesk@endia.sk"
                    );
                });
    };

    const sendEmailForPaymentRepeat = () => {
        uuid &&
            sendEmail(uuid, config.emailTypeCodes.repreat_payment_email)
                .then(() => toast("Odkaz na platbu bol odoslaný na Váš email."))
                .catch(() => toast("Pri odosielaní emailu nastala chyba."));
    };

    return (
        <div className="messageBox">
            <h2>Ďakujeme!</h2>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/success.png`}
                    alt="success"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Vaša objednávka č. {orderNumber} bola úspešne zaevidovaná.
                </div>
            </Row>
            <Row className="messageBoxItemRow" align="center">
                <img
                    src={`${publicUrl}/assets/img/icons/fail.png`}
                    alt="fail"
                    width="50"
                    height="50"
                />
                <div className="messageBoxItem">
                    Vaša platba nebola úspešná.
                </div>
            </Row>
            <Row>
                <div className="messageBoxItemDescription">
                    <p>{description}</p>
                    <p>
                        Ak na odstránenie prekážky v platbe potrebujete čas
                        (napr. expirovaná karta, alebo nemáte pri sebe mobil pre
                        overovací kód platby a pod.), môžete si odoslať odkaz na
                        neskoršie opakovanie platby na svoju e-mailovú adresu.
                    </p>
                    <Row justify="flex-end">
                        <button
                            className="button sendEmailButton"
                            onClick={() => sendEmailForPaymentRepeat()}
                        >
                            Odoslať odkaz
                        </button>
                    </Row>
                    <Row
                        className="formButtonContainer msgButtonContainer"
                        gap="5px"
                        wrap="wrap"
                    >
                        <Col className="msgButtonCol">
                            <button
                                className="button pink"
                                onClick={handleRePayBtnClick}
                            >
                                Opakovať platbu
                            </button>
                        </Col>
                        <Col className="msgButtonCol">
                            <button
                                className="button"
                                onClick={() =>
                                    setShowSupportMsgInput(!showSupportMsgInput)
                                }
                            >
                                {showSupportMsgInput
                                    ? "Zavrieť kontaktný formulár"
                                    : "Kontaktovať podporu"}
                            </button>
                        </Col>
                    </Row>
                    {showSupportMsgInput && (
                        <Col className="contactSupportContainer">
                            <span className="fontW400">
                                KONTAKTOVAŤ PODPORU:
                            </span>
                            <Input<TextAreaInputProps>
                                component={TextAreaInput}
                                label=""
                                id="supportMsg"
                                placeholder="Tu popíšte problém, s ktorým by Vám naši pracovníci mohli pomôcť"
                                value={message}
                                onChange={(val) => setMessage(val)}
                            />
                            <button
                                className="button supportMsgBtn"
                                onClick={() => sendEmailToSupport()}
                            >
                                Odoslať správu
                            </button>
                        </Col>
                    )}
                </div>
            </Row>
        </div>
    );
};

export default PaymentFail;
