import React, { FC } from "react";
import cn from "classnames";

interface Props {
    width?: number;
    justify?: string;
    align?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    reff?: React.RefObject<HTMLDivElement>;
    wrap?:
        | "wrap"
        | "nowrap"
        | "-moz-initial"
        | "inherit"
        | "initial"
        | "revert"
        | "unset"
        | "wrap-reverse";
}

const Col: FC<Props> = ({
    width,
    justify,
    align,
    className,
    wrap,
    onClick,
    reff,
    ...props
}) => {
    const style = {
        flex: width,
        justifyContent: justify || "flex-start",
        alignItems: align || "stretch",
        flexWrap: wrap || "nowrap",
    };
    return (
        <div
            className={cn("customCol", className)}
            style={style}
            onClick={onClick}
            ref={reff}
        >
            {props.children}
        </div>
    );
};

export default Col;
