import React, { FC } from "react";
import cn from "classnames";
import Row from "../../Row";

export interface CheckboxInputProps {
    id?: string;
    name?: string;
    label?: string | React.ReactNode;
    labelPlacement?: "start" | "end";
    value: boolean;
    errors?: string[];
    classNames?: string;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    onChange: (val: boolean) => void;
}

const CheckboxInput: FC<CheckboxInputProps> = ({
    id,
    name,
    label,
    labelPlacement = "start",
    value,
    errors,
    classNames,
    onChange,
    inputRef,
    ...props
}) => {
    const input = (
        <input
            type="checkbox"
            className={cn("mx-2", classNames)}
            id={id}
            name={name}
            checked={value}
            onChange={(e) => onChange(e.currentTarget.checked)}
            ref={inputRef}
        />
    );

    return (
        <Row align="center" className="py-2">
            {labelPlacement === "start" ? (
                <>
                    <span className="input-checkbox-text">{label}</span>
                    {input}
                </>
            ) : (
                <>
                    {input}
                    <span className="input-checkbox-text">{label}</span>
                </>
            )}
        </Row>
    );
};

export default CheckboxInput;
