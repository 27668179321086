import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { postData, redeemCoupon, getPrice } from "../../helpers/fetchHelpers";
import { AppContext } from "../../App";
import Row from "../Row";
import Col from "../Col";
import Input from "./Input";
import RadioLikePickerGroup, {
    RadioLikePickerGroupProps,
} from "./inputs/RadioLikePickerGroup";
import Monthpicker, { MonthpickerProps } from "./inputs/Monthpicker";
import TextInput, { TextInputProps } from "./inputs/TextInput";
import Tooltip from "../Tooltip";
import StepProgress from "./StepProgress";
import { calculatePeriodEnd, formatPeriodDate } from "../../helpers/helpers";

interface Props {
    onBack: () => void;
    onSubmit: () => void;
}

const FormStep2: FC<Props> = ({ onBack, onSubmit, ...props }) => {
    const { step } = useContext(AppContext);
    const {
        uuid,
        values,
        setValuesByKeys,
        errors,
        setErrors,
        setErrorByKey,
    } = useContext(AppContext);
    const inputRefObject = useRef({
        companyIco: {
            order: 1,
            ref: useRef<any>(null),
        },
        companyName: {
            order: 2,
            ref: useRef<any>(null),
        },
        companyEic: {
            order: 3,
            ref: useRef<any>(null),
        },
        dic: {
            order: 4,
            ref: useRef<any>(null),
        },
        icdph: {
            order: 5,
            ref: useRef<any>(null),
        },
        street: {
            order: 6,
            ref: useRef<any>(null),
        },
        city: {
            order: 7,
            ref: useRef<any>(null),
        },
        postalCode: {
            order: 8,
            ref: useRef<any>(null),
        },
        period: {
            order: 9,
            ref: useRef<any>(null),
        },
        periodStart: {
            order: 10,
            ref: useRef<any>(null),
        },
    });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (submitted) {
            let inputKey,
                lowestOrder = 1000;
            Object.entries(errors).forEach(([key, val]) => {
                if (
                    val &&
                    val.length &&
                    inputRefObject.current[key] &&
                    inputRefObject.current[key].order < lowestOrder
                ) {
                    lowestOrder = inputRefObject.current[key].order;
                    inputKey = key;
                }
            });
            inputRefObject.current[inputKey] &&
                inputRefObject.current[inputKey].ref &&
                window.scrollTo(
                    0,
                    inputRefObject.current[inputKey].ref.current.offsetTop - 120
                );
            setSubmitted(false);
        }
    }, [submitted, inputRefObject, errors]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function validateIco(value: string) {
        if (value.length !== 8) {
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        uuid &&
            postData(uuid, values, step).then((res) => {
                if (res === "success") {
                    onSubmit();
                } else {
                    setErrors(res);
                    setSubmitted(true);
                }
            });
    };

    const handleBack = () => onBack();

    const handleRedeemCoupon = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        uuid &&
            redeemCoupon(uuid, values.coupon)
                .then((res) => {
                    if (res === "valid") {
                        toast("Kupón bol uplatnený");
                        uuid &&
                            getPrice(uuid, values.period, values.coupon).then(
                                (res) => {
                                    setValuesByKeys(
                                        [
                                            "couponRedeemed",
                                            "price",
                                            "discountRate",
                                            "calculatedDiscount",
                                            "discountedPrice",
                                            "discountedVatPrice",
                                        ],
                                        [
                                            true,
                                            res.price,
                                            res.discount_rate,
                                            res.calculated_discount,
                                            res.discounted_price,
                                            res.discounted_vat_price,
                                        ]
                                    );
                                }
                            );
                    } else {
                        setErrorByKey("coupon", [
                            "Kód zľavového kupónu je neplatný, alebo po lehote platnosti. Prosím zadajte platný kód kupónu, alebo nechajte pole prázdne",
                        ]);
                    }
                })
                .catch(() =>
                    setErrorByKey("coupon", [
                        "Kód zľavového kupónu je neplatný, alebo po lehote platnosti. Prosím zadajte platný kód kupónu, alebo nechajte pole prázdne",
                    ])
                );
    };

    const handleChangePeriod = (val: number) => {
        setValuesByKeys(["period"], [val]);
        setErrorByKey("period", undefined);
        uuid &&
            getPrice(uuid, val, values.coupon).then((res) => {
                setValuesByKeys(
                    [
                        "period",
                        "price",
                        "discountRate",
                        "calculatedDiscount",
                        "discountedPrice",
                        "discountedVatPrice",
                    ],
                    [
                        val,
                        res.price,
                        res.discount_rate,
                        res.calculated_discount,
                        res.discounted_price,
                        res.discounted_vat_price,
                    ]
                );
            });
    };

    const IcoOnChangeHandler = (companyIco: string) => {
        setValuesByKeys(["companyIco"], [companyIco]);
        if (validateIco(companyIco)) {
            // TODO: ked bude endpoint napojit
            // getCompanyData(companyIco).then((companyData) => {
            //     if (companyData) {
            //         setValues({ ...values, ...companyData });
            //     }
            // });
        }
    };

    function formatNumber(val: number | null) {
        return val
            ? val
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  .replace(".", ",")
            : "";
    }

    const periodEndDate = calculatePeriodEnd(values.period, values.periodStart);
    const periodEnd = formatPeriodDate(periodEndDate);

    return (
        <>
            <StepProgress
                onNextStepClick={handleSubmit}
                onPrevStepClick={handleBack}
            />
            <Input<TextInputProps>
                errors={errors.companyIco}
                label="IČO spoločnosti *"
                component={TextInput}
                id="ico"
                value={values.companyIco}
                inputRef={inputRefObject.current.companyIco.ref}
                onChange={IcoOnChangeHandler}
                onBlur={() => setErrorByKey("companyIco", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.companyName}
                label="Názov spoločnosti *"
                component={TextInput}
                id="company"
                value={values.companyName}
                inputRef={inputRefObject.current.companyName.ref}
                onChange={(val) => setValuesByKeys(["companyName"], [val])}
                onBlur={() => setErrorByKey("companyName", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.companyEic}
                label="EIC odberného miesta (uvedené na faktúre za energiu) *"
                component={TextInput}
                id="eic"
                value={values.companyEic}
                inputRef={inputRefObject.current.companyEic.ref}
                onChange={(val) => setValuesByKeys(["companyEic"], [val])}
                onBlur={() => setErrorByKey("companyEic", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.dic}
                label={
                    <>
                        DIČ{" "}
                        <Tooltip>
                            Zadaný údaj bude použitý na vystavenom daňovom
                            doklade.
                        </Tooltip>
                    </>
                }
                component={TextInput}
                id="dic"
                value={values.dic}
                inputRef={inputRefObject.current.dic.ref}
                onChange={(val) => setValuesByKeys(["dic"], [val])}
                onBlur={() => setErrorByKey("dic", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.icdph}
                label={
                    <>
                        IČ DPH{" "}
                        <Tooltip>
                            Zadaný údaj bude použitý na vystavenom daňovom
                            doklade.
                        </Tooltip>
                    </>
                }
                component={TextInput}
                id="icdph"
                value={values.icdph}
                inputRef={inputRefObject.current.icdph.ref}
                onChange={(val) => setValuesByKeys(["icdph"], [val])}
                onBlur={() => setErrorByKey("icdph", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.street}
                label="Adresa - Ulica a číslo *"
                component={TextInput}
                id="street"
                value={values.street}
                inputRef={inputRefObject.current.street.ref}
                onChange={(val) => setValuesByKeys(["street"], [val])}
                onBlur={() => setErrorByKey("street", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.city}
                label="Obec *"
                component={TextInput}
                id="city"
                value={values.city}
                inputRef={inputRefObject.current.city.ref}
                onChange={(val) => setValuesByKeys(["city"], [val])}
                onBlur={() => setErrorByKey("city", undefined)}
            />
            <Input<TextInputProps>
                errors={errors.postalCode}
                label="PSČ *"
                component={TextInput}
                id="postalCode"
                value={values.postalCode}
                inputRef={inputRefObject.current.postalCode.ref}
                onChange={(val) => setValuesByKeys(["postalCode"], [val])}
                onBlur={() => setErrorByKey("postalCode", undefined)}
            />
            <Input<RadioLikePickerGroupProps>
                errors={errors.period}
                label="Posudzované obdobie (zvoľte počet mesiacov) *"
                component={RadioLikePickerGroup}
                value={values.period}
                options={[
                    { name: "6", value: 6 },
                    { name: "7", value: 7 },
                    { name: "8", value: 8 },
                    { name: "9", value: 9 },
                    { name: "10", value: 10 },
                    { name: "11", value: 11 },
                    { name: "12", value: 12 },
                ]}
                inputRef={inputRefObject.current.period.ref}
                onChange={handleChangePeriod}
            />
            <Input<MonthpickerProps>
                errors={errors.periodStart}
                label="Začiatok posudzovaného obdobia *"
                component={Monthpicker}
                placeholder="Formát dátumu: MM-RRRR (napr. 08-2019)"
                value={values.periodStart}
                inputRef={inputRefObject.current.periodStart.ref}
                onChange={(val) => {
                    setValuesByKeys(["periodStart"], [val]);
                }}
                onBlur={() => setErrorByKey("periodStart", undefined)}
            />
            {periodEndDate && (
                <Input<TextInputProps>
                    // errors={
                    //     new Date() < periodEndDate
                    //         ? [
                    //               "Koniec posudzovaného obdobia nemôže byť v budúcnosti. Zmeňte začiatok, alebo počet mesiacov posudzovaného obdobia.",
                    //           ]
                    //         : []
                    // }
                    label="Koniec posudzovaného obdobia"
                    component={TextInput}
                    value={periodEnd || ""}
                    onChange={() => {}}
                    disabled
                />
            )}
            <Row wrap="wrap" align="flex-end">
                <Col>
                    <Input<TextInputProps>
                        errors={errors.coupon}
                        label="Zľavový kupón"
                        component={TextInput}
                        id="coupon"
                        value={values.coupon || ""}
                        onChange={(val) => setValuesByKeys(["coupon"], [val])}
                        onBlur={() => setErrorByKey("coupon", undefined)}
                        disabled={values.couponRedeemed}
                    />
                </Col>
            </Row>
            <Row>
                <Col width={2}></Col>
                <Col width={1} className="redeemCouponBtnCol">
                    <button
                        className="button redeemCouponBtn"
                        onClick={handleRedeemCoupon}
                        disabled={values.couponRedeemed}
                    >
                        Uplatniť zľavu
                    </button>
                </Col>
            </Row>
            {values.couponRedeemed && values.period ? (
                <Col className="priceSummary">
                    <div>
                        Základná cena: {formatNumber(values.price)} Eur bez DPH
                    </div>
                    <div className="discount">
                        Uplatnená zľava:&#160;&#160;-
                        {formatNumber(values.calculatedDiscount)} Eur (
                        {values.discountRate}%)
                    </div>
                    <div className="finalPrice">
                        <div className="line"></div>
                        Výsledná cena:{" "}
                        {values.discountedPrice && (
                            <>
                                {formatNumber(values.discountedPrice)} Eur bez
                                DPH ({formatNumber(values.discountedVatPrice)}{" "}
                                Eur s DPH)
                            </>
                        )}
                    </div>
                </Col>
            ) : (
                <Col className="priceSummary">
                    <div className="finalPrice">
                        Výsledná cena:{" "}
                        {values.discountedPrice && (
                            <>
                                {formatNumber(values.discountedPrice)} Eur bez
                                DPH ({formatNumber(values.discountedVatPrice)}{" "}
                                Eur s DPH)
                            </>
                        )}
                    </div>
                </Col>
            )}
            <Row className="formButtonContainer" gap="5px" wrap="wrap">
                <Col width={1}>
                    <button className="button" onClick={handleBack}>
                        Predchádzajúci krok
                    </button>
                </Col>
                <Col width={1}>
                    <button
                        className="button pink"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        Prejsť na ďalší krok
                    </button>
                </Col>
            </Row>
        </>
    );
};

export default FormStep2;
