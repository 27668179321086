import React from "react";
import Col from "../../Col";
import Row from "../../Row";

interface Option<T> {
    label: string | React.ReactNode;
    value: T;
}

export interface RadioGroupProps<T> {
    name: string;
    label?: string | React.ReactNode;
    value: T | null;
    options: Option<T>[];
    onChange: (val: T) => void;
    // direction?: "row" | "column";
    inputRef?: React.MutableRefObject<HTMLInputElement>;
}

function RadioGroup<T = string>({
    name,
    label,
    value,
    options,
    onChange,
    // direction = "column",
    inputRef,
    ...props
}: RadioGroupProps<T>) {
    return (
        <div className="radioGroup">
            {label && (
                <>
                    <label>{label}</label>
                    <br />
                </>
            )}
            {(options || []).map((opt, id) => (
                <Row key={id} align="baseline">
                    <Col className="inputCol">
                        <input
                            name={name}
                            id={"opt_" + opt.value}
                            type="radio"
                            //@ts-ignore
                            value={opt.value}
                            checked={value === opt.value}
                            onChange={(e) => onChange(opt.value)}
                            ref={inputRef}
                        />
                    </Col>
                    <Col>
                        <label htmlFor={"opt_" + opt.value}>{opt.label}</label>
                    </Col>
                </Row>
            ))}
        </div>
    );
}

export default RadioGroup;
