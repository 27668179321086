import React, { useRef, useState } from "react";
import Col from "../../../Col";
import Modal from "../../../Modal";
import Row from "../../../Row";

const HowItWorksVideo = () => {
    const [openModal, setOpenModal] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const publicUrl = process.env.PUBLIC_URL;

    const handlePlayVideoClick = () => {
        setOpenModal(true);
        videoRef.current && videoRef.current.play();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        initVideo();
    };

    const initVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <div id="hw-video" className="pd-default-120">
            <div className="container">
                <Row
                    className="hwVideoRow wow animated fadeInRight"
                    data-wow-duration="0.6s"
                    data-wow-delay="0.1s"
                    wrap="wrap-reverse"
                    align="center"
                >
                    <Col>
                        <div className="howItWorksVideoWrapper">
                            <img
                                src={`${publicUrl}/assets/img/hw-video-img.png`}
                                alt="hw-video"
                            />
                            <img
                                className="playIcon"
                                src={`${publicUrl}/assets/img/icons/video_play.png`}
                                alt="play"
                                onClick={handlePlayVideoClick}
                            />
                        </div>
                        <Row
                            className="playVideoBtnRow mobile"
                            justify="center"
                        >
                            <button
                                className="button playVideoBtn wow animated fadeInLeft"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                                onClick={handlePlayVideoClick}
                            >
                                Spustiť video
                            </button>
                        </Row>
                    </Col>
                    <Col>
                        <div className="section-title text-center margin-bottom-90">
                            <h2
                                className="title wow animated fadeInLeft"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                            >
                                Ako to funguje sa dozviete za jednu minútu
                            </h2>
                        </div>
                        <Row
                            className="playVideoBtnRow desktop"
                            justify="center"
                        >
                            <button
                                className="button playVideoBtn wow animated fadeInLeft"
                                data-wow-duration="0.6s"
                                data-wow-delay="0.1s"
                                onClick={handlePlayVideoClick}
                            >
                                Spustiť video
                            </button>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Modal
                classNames="videoModal"
                open={openModal}
                padding="0px 0px"
                closeHandler={handleCloseModal}
            >
                <video ref={videoRef} className="howItWorksVideo" controls>
                    <source
                        src={`${publicUrl}/assets/video/how_it_works.mp4`}
                        type="video/mp4"
                    />
                </video>
                <img
                    className="laptopImage"
                    src={`${publicUrl}/assets/img/hw-video-img.png`}
                    alt="hw-video"
                    onClick={handleCloseModal}
                />
            </Modal>
        </div>
    );
};

export default HowItWorksVideo;
