import React, { FC } from "react";
import cn from "classnames";

import Row from "../../Row";

interface Option {
    name: string;
    value: number;
}

export interface RadioLikePickerGroupProps {
    label?: string | React.ReactNode;
    value: number | null;
    options: Option[];
    onChange: (val: number) => void;
    inputRef?: React.MutableRefObject<HTMLDivElement>;
}

const RadioLikePickerGroup: FC<RadioLikePickerGroupProps> = ({
    label,
    value,
    options,
    onChange,
    inputRef,
    ...props
}) => {
    return (
        <>
            <label>{label}</label>
            <div ref={inputRef}>
                <Row className="radioLikePickerGroup" wrap="wrap">
                    {options.map((o, id) => (
                        <div
                            className={cn([
                                "radioLikePickerOption",
                                o.value === value ? "selectedOption" : "",
                            ])}
                            key={id}
                            onClick={(e) => onChange(o.value)}
                        >
                            {o.name}
                        </div>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default RadioLikePickerGroup;
