import React from "react";

const Protocol = () => {
    let publicUrl = process.env.PUBLIC_URL;

    return (
        <div id="protocol" className="protocolSection">
            <div className="protocolBg sbs-what-riyaqas pd-default-120">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 wow animated fadeInRight"
                            data-wow-duration="1s"
                            data-wow-delay="0.3s"
                        >
                            <img
                                src={`${publicUrl}/assets/img/protocol.png`}
                                alt="protocol"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-6 desktop-center-item">
                            <div
                                className="desktop-center-area wow animated fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="0.3s"
                            >
                                <div className="section-title style-two">
                                    <h2 className="title">
                                        Energetická diagnostika, ktorá Vám
                                        všetko objasní
                                    </h2>
                                    <p>
                                        Dostanete do rúk analýzu, ktorá je jasná
                                        a zrozumiteľná. Informácie sú spracované
                                        v jasnej štruktúre, aby ste vedeli
                                        okamžite zrealizovať úsporné opatrenia.
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="riyaqas-check-list">
                                            <img
                                                src={`${publicUrl}/assets/img/icons/check.png`}
                                                alt="check"
                                            />
                                            <span>Celkové hodnotenie</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="riyaqas-check-list">
                                            <img
                                                src={`${publicUrl}/assets/img/icons/check.png`}
                                                alt="check"
                                            />
                                            <span>Možná úspora</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="riyaqas-check-list">
                                            <img
                                                src={`${publicUrl}/assets/img/icons/check.png`}
                                                alt="check"
                                            />
                                            <span>Postup, čo treba robiť</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="riyaqas-check-list">
                                            <img
                                                src={`${publicUrl}/assets/img/icons/check.png`}
                                                alt="check"
                                            />
                                            <span>Grafy spotreby</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Protocol;
