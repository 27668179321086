import React, { FC } from "react";

export interface TextInputProps {
    id?: string;
    name?: string;
    label?: string | React.ReactNode;
    value: string;
    errors?: string[];
    classNames?: string;
    labelClassNames?: string;
    disabled?: boolean;
    pattern?: string;
    placeholder?: string;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    onChange: (val: string) => void;
    onBlur?: () => void;
}

const TextInput: FC<TextInputProps> = ({
    id,
    name,
    label,
    value,
    errors,
    classNames,
    labelClassNames,
    disabled = false,
    pattern,
    placeholder,
    onChange,
    onBlur,
    inputRef,
    ...props
}) => {
    return (
        <>
            <label className={labelClassNames}>{label}</label>
            <input
                type="text"
                className={classNames}
                id={id}
                name={name}
                value={value}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={disabled}
                pattern={pattern}
                placeholder={placeholder}
                onBlur={() => {
                    onBlur && onBlur();
                }}
                ref={inputRef}
            />
        </>
    );
};

export default TextInput;
