import React, { FC } from "react";
import cn from "classnames";
import styled from "styled-components";

interface Props {
    justify?: string;
    align?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    reff?: React.RefObject<HTMLDivElement>;
    wrap?:
        | "wrap"
        | "nowrap"
        | "-moz-initial"
        | "inherit"
        | "initial"
        | "revert"
        | "unset"
        | "wrap-reverse";
    gap?: string;
}

const Row: FC<Props> = ({
    justify,
    align,
    className,
    wrap,
    onClick,
    gap,
    reff,
    ...props
}) => {
    const style = {
        justifyContent: justify || "flex-start",
        alignItems: align || "stretch",
        flexWrap: wrap || "nowrap",
        ...(gap && { margin: `-${gap}` }),
    };
    return (
        <StyledDiv
            className={cn("customRow", className)}
            style={style}
            gap={gap}
            onClick={onClick}
            ref={reff}
        >
            {props.children}
        </StyledDiv>
    );
};

const StyledDiv = styled(({ gap, ...otherProps }) => <div {...otherProps} />)`
    & > .customCol {
        margin: ${(props) => props.gap};
    }
`;

export default Row;
