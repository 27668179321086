import React, { FC, useState, useContext, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { setCookie } from "../../helpers/cookiesHelpers";
import { postData } from "../../helpers/fetchHelpers";
import { config } from "../../config";
import { AppContext } from "../../App";
import Input from "./Input";
import TextInput, { TextInputProps } from "./inputs/TextInput";
import CheckboxInput, { CheckboxInputProps } from "./inputs/CheckboxInput";
import StepProgress from "./StepProgress";
import { Link, useRouteMatch } from "react-router-dom";
import Row from "../Row";
import Col from "../Col";
import Preloader from "../Preloader";

interface Props {
    onSubmit: () => void;
}

const FormStep1: FC<Props> = ({ onSubmit, ...props }) => {
    const {
        uuid,
        values,
        setValuesByKeys,
        errors,
        setErrors,
        setErrorByKey,
        step,
    } = useContext(AppContext);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState("");
    const { url } = useRouteMatch();
    const inputRefObject = useRef({
        forCompanyConfirm: {
            order: 1,
            ref: useRef<any>(null),
        },
        email: {
            order: 2,
            ref: useRef<any>(null),
        },
        fullname: {
            order: 3,
            ref: useRef<any>(null),
        },
        phone: {
            order: 4,
            ref: useRef<any>(null),
        },
        gdprAgree: {
            order: 5,
            ref: useRef<any>(null),
        },
    });
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (submitted) {
            let inputKey,
                lowestOrder = 1000;
            Object.entries(errors).forEach(([key, val]) => {
                if (
                    val &&
                    val.length &&
                    inputRefObject.current[key] &&
                    inputRefObject.current[key].order < lowestOrder
                ) {
                    lowestOrder = inputRefObject.current[key].order;
                    inputKey = key;
                }
            });
            inputRefObject.current[inputKey] &&
                inputRefObject.current[inputKey].ref &&
                window.scrollTo(
                    0,
                    inputRefObject.current[inputKey].ref.current.offsetTop - 120
                );
            setSubmitted(false);
        }
    }, [submitted, inputRefObject, errors]);

    const recaptchaOnChange = (value: any) => {
        if (value) {
            setRecaptchaError("");
            setRecaptchaVerified(true);
        } else {
            setRecaptchaVerified(false);
        }
    };

    const handleSubmit = () => {
        if (recaptchaVerified) {
            if (uuid) {
                setLoading(true);
                postData(uuid, values, step).then((res) => {
                    if (res === "success") {
                        setCookie("cookiesAgreement", true);
                        setCookie("uuid", uuid);
                        setLoading(false);
                        onSubmit();
                    } else {
                        setLoading(false);
                        setErrors(res);
                        setSubmitted(true);
                    }
                });
            }
        } else {
            setRecaptchaError(
                "Prosím zaškrtnite pole reCaptcha pred pokračovaním, aby sme overili, že nie ste webový robot."
            );
        }
    };

    return (
        <>
            {url.startsWith("/order") ? (
                <StepProgress onNextStepClick={handleSubmit} />
            ) : (
                <Preloader show={loading} />
            )}
            <Input<CheckboxInputProps>
                component={CheckboxInput}
                errors={errors.forCompanyConfirm}
                id="forCompanyConfirm"
                value={values.forCompanyConfirm}
                inputRef={inputRefObject.current.forCompanyConfirm.ref}
                onChange={(val) => {
                    setValuesByKeys(["forCompanyConfirm"], [val]);
                    setErrorByKey("forCompanyConfirm", undefined);
                }}
                label="Diagnostika bude spracovaná pre spoločnosť"
                labelPlacement="end"
            />
            <Input<TextInputProps>
                component={TextInput}
                errors={errors.email}
                label="Emailová adresa *"
                id="email"
                value={values.email}
                inputRef={inputRefObject.current.email.ref}
                onChange={(val) => setValuesByKeys(["email"], [val])}
                onBlur={() => setErrorByKey("email", undefined)}
            />
            <Input<TextInputProps>
                component={TextInput}
                errors={errors.fullname}
                label="Meno a Priezvisko *"
                id="fullname"
                value={values.fullname}
                inputRef={inputRefObject.current.fullname.ref}
                onChange={(val) => setValuesByKeys(["fullname"], [val])}
                onBlur={() => setErrorByKey("fullname", undefined)}
            />
            <Input<TextInputProps>
                component={TextInput}
                errors={errors.phone}
                label="Telefónne číslo *"
                id="phone"
                value={values.phone}
                inputRef={inputRefObject.current.phone.ref}
                onChange={(val) => setValuesByKeys(["phone"], [val])}
                onBlur={() => setErrorByKey("phone", undefined)}
            />
            <Input<CheckboxInputProps>
                component={CheckboxInput}
                errors={errors.gdprAgree}
                id="gdprAgree"
                value={values.gdprAgree}
                inputRef={inputRefObject.current.gdprAgree.ref}
                onChange={(val) => {
                    setValuesByKeys(["gdprAgree"], [val]);
                    setErrorByKey("gdprAgree", undefined);
                }}
                label={
                    <>
                        Súhlasím so{" "}
                        <Link to="/gdpr" target="_blank">
                            spracovaním osobných údajov
                        </Link>
                    </>
                }
                labelPlacement="end"
            />
            <ReCAPTCHA
                sitekey={config.recaptcha.sitekey}
                onChange={recaptchaOnChange}
            />
            {recaptchaError && (
                <div className="inputErrorMessage">{recaptchaError}</div>
            )}
            <Row className="formButtonContainer">
                <Col>
                    <button
                        className="button pink"
                        title="Pokračovať na ďalší krok"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleSubmit();
                        }}
                    >
                        {url.startsWith("/order")
                            ? "Prejsť na ďalší krok"
                            : "Pokračovať"}
                    </button>
                </Col>
            </Row>
        </>
    );
};

export default FormStep1;
